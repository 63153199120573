import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../../AuthContext';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import './BinanceRSI.css';
import Modal from '../../authentication/modal/Modal';

const DetailsRSI = ({ rsiDetails }) => {
  const { auth } = useContext(AuthContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('register');

  const openModal = (tab) => {
    setActiveTab(tab);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  const handleKeyDown = (event, tab) => {
    if (event.key === 'Enter') {
      openModal(tab);
    }
  };

  const formatTime = (time) => {
    const date = new Date(time);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const formattedTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedTime;
  };

  const sortedDetails = rsiDetails.sort((a, b) => new Date(b.time) - new Date(a.time));

  const exportToCSV = () => {
    const csvContent = [
      'Time,Close,RSI,Signal,Success',
      ...sortedDetails.map((item) =>
        `${formatTime(item.time)},${item.close.toFixed(8)},${item.rsi.toFixed(2)},${item.signal || 'No Signal'},${item.signal ? (item.success ? 'Success' : 'Fail') : 'n/a'}`
      ),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'rsi_details.csv';
    link.click();
  };

  return (
    <>
    <div className="details-rsi">
      <h4>RSI Details 
      {auth.token && ( 
        <button className="button-csv" onClick={exportToCSV}>
          <FontAwesomeIcon icon={faDownload} className="download-icon" /> 
          Export to CSV
        </button>
      )}
      {!auth.token && ( 
        <button className="button-csv"  onClick={() => openModal('login')} onKeyDown={(e) => handleKeyDown(e, 'login')}>
          <FontAwesomeIcon icon={faDownload} className="download-icon"/> 
          Login to Export to CSV
        </button>
      )}
      </h4>
      <table>
        <thead>
          <tr>
            <th>Time</th>
            <th>Close</th>
            <th>RSI</th>
            <th>Signal</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {!auth.token && ( 
          <>
          {sortedDetails.slice(0, 4).map((item, index) => (
            <tr key={index}>
              <td>{formatTime(item.time)}</td>
              <td>{item.close.toFixed(8)}</td>
              <td>{item.rsi.toFixed(2)}</td>
              <td>{item.signal || 'No Signal'}</td>
              <td>
                {index === 0 ? '' : (item.signal ? (item.success ? 'Success' : 'Fail') : 'n/a')}
              </td>
            </tr>
          ))}
          </>
        )}

        {auth.token && ( 
          <>
          {sortedDetails.map((item, index) => (
            <tr key={index}>
              <td>{formatTime(item.time)}</td>
              <td>{item.close.toFixed(8)}</td>
              <td>{item.rsi.toFixed(2)}</td>
              <td>{item.signal || 'No Signal'}</td>
              <td>
                {index === 0 ? '' : (item.signal ? (item.success ? 'Success' : 'Fail') : 'n/a')}
              </td>
            </tr>
          ))}
          </>
        )}
        </tbody>
      </table>
      <br>
      </br>
      {!auth.token && (
      <button className="button-reg" onClick={() => openModal('register')} onKeyDown={(e) => handleKeyDown(e, 'register')} rel="noopener noreferrer">
        Register to access the full dataset
      </button>
      )}
    </div>
    <Modal isOpen={isModalOpen} onClose={closeModal} activeTab={activeTab} />
    </>
  );
};

export default DetailsRSI;
