import React, { useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import './Xumm.css';
import { API_BASE_URL } from '../../config';

function XummSubscriptionButton() {
  const popupRef = useRef(null); 
  const navigate = useNavigate();
  const { auth, setSubscriptionData  } = useContext(AuthContext);

  const payWithCoinbase = () => {
    // Programmatically navigate
    window.open('https://commerce.coinbase.com/checkout/484c294e-60bc-47be-b846-7996b97c9178', '_blank');
  };

  const initiateSignIn = () => {
        let eventSource;
        if (auth.email) {
            eventSource = new EventSource(`${API_BASE_URL}/user/xumm-subscription-email?email=${encodeURIComponent(auth.email)}`);
        } else {
            eventSource = new EventSource(`${API_BASE_URL}/user/xumm-subscription`);
        }
    
        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.uuid) {
                popupRef.current = window.open(data.signinUrl.always, 'XummPayment', 'width=600,height=600');
            } else if (data && data.message === 'IsPaid') {
                if (data.subscription) {
                    setSubscriptionData(data.subscription);
                } else {
                    console.log("No subscription data found in server response.");
                }
                
                navigate('/');
                eventSource.close();
                
                if (popupRef.current && !popupRef.current.closed) {
                    popupRef.current.close();
                }
            } else if (data && data.message === 'NotPaid') {
                    navigate('/not-paid');
                    eventSource.close();

                    if (popupRef.current && !popupRef.current.closed) {
                        popupRef.current.close();
                    }
                } else if (data.error && data.error === 'Payload expired') {
                    if (popupRef.current && !popupRef.current.closed) {
                        popupRef.current.close();
                    }
                    eventSource.close();
                }
            };

            eventSource.onerror = (error) => {
                if (eventSource.readyState === EventSource.CLOSED) {
                    console.log("EventSource connection was closed by the server.");
                } else if (eventSource.readyState === EventSource.CONNECTING) {
                    console.log("EventSource is trying to reconnect.");
                } else {
                    console.error("EventSource encountered an error:", error);
                }
                eventSource.close();
            };

            return () => {
                eventSource.close();
                if (popupRef.current && !popupRef.current.closed) {
                    popupRef.current.close();
                }
            };
        };

        return (
        <div>
            <button className='xumm-button' onClick={initiateSignIn}>Pay with Xaman</button>
            <button className='xumm-button' onClick={payWithCoinbase}>Pay with Coinbase</button>

        </div>
    );
}

export default XummSubscriptionButton;
