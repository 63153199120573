import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PricingCardFree.css';
import checkBlack from '../../../images/check-black.png'


function PricingCardFree(props) {
  const navigate = useNavigate();
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <>
    <div className="free-price-card">
      <div className="free-price-card-title">{props.title}</div>
      <h2 className='free-title'>Basic
        <p className='free-paragraph'>Get started for free with essential tools.</p>
      </h2>
      <div className='free-price-list'>
        <div className='free-price-item'><img src={checkBlack} alt="Check" className='check-black' />&#160;&#160;{props.one}</div>
        {/* <div className='free-price-item'><img src={checkBlack} alt="Check" className='check-black' />&#160;&#160;{props.two}</div>
        <div className='free-price-item'><img src={checkBlack} alt="Check" className='check-black' />&#160;&#160;{props.three}</div>
        <div className='free-price-item'><img src={checkBlack} alt="Check" className='check-black' />&#160;&#160;{props.four}</div>
        <div className='free-price-item'><img src={checkBlack} alt="Check" className='check-black' />&#160;&#160;&#160;{props.five}</div> */}
      </div>
      <button className="free-price-button" onClick={() => navigate('/')}>Get it for {capitalizeFirstLetter(props.subtitle)}</button>
    </div>
    </>
  );
}

export default PricingCardFree;
