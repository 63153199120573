import './DashboardCalculator.css';
import Form from '../../form/Form';

const DashboardCalculator = (props) => {
  return (
    <div className="background-container">
      <h1 className="dash-mobile-h1">ISR CALCULATOR</h1>
      <p className="subtitle-calc">
        {/* <img className='coin' src='https://s2.coinmarketcap.com/static/img/coins/64x64/52.png' alt="XRP" />XRPL Grant Recipient  */}
      </p>
      <div className="card">
        <Form />
        
      </div>
    </div>
  );
}

export default DashboardCalculator;
