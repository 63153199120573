import React, { useState, useEffect, useContext } from 'react';
import Modal from '../authentication/modal/Modal';
import { AuthContext } from '../AuthContext';
import './Form.css';
import BinanceSMA from '../indicators/binanceSMA/BinanceSMA';
import BinanceRSI from '../indicators/binanceRSI/BinanceRSI';
import BinanceEMA from '../indicators/binanceEMA/BinanceEMA';
import BinanceOBV from '../indicators/binanceOBV/BinanceOBV';
import { getTradingPairs } from '../../api/binance';
import Select from 'react-select';

const Form = () => {
  const { auth } = useContext(AuthContext);

  const [isModalOpen, setModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('register');

  const openModal = (tab) => {
    setActiveTab(tab);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  const handleKeyDown = (event, tab) => {
    if (event.key === 'Enter') {
      openModal(tab);
    }
  };

  const [indicator, setIndicator] = useState('RSI');
  const [interval, setInterval] = useState('1h');
  const [symbol, setSymbol] = useState(null);
  const [limit, setLimit] = useState('300');

  const intervals = ['1m', '3m', '5m', '15m', '30m', '1h', '2h', '4h', '6h', '12h', '1d'];
  const limits = ['300', '400', '500', '600', '700', '800', '900', '1000'];
  const [tradingPairs, setTradingPairs] = useState([]);

  const indicatorOptions = [
    { value: 'RSI', label: 'Relative Strength Index (RSI)' },
    { value: 'SMA', label: 'Simple Moving Average (SMA)' },
    { value: 'EMA', label: 'Exponential Moving Average (EMA) 5-8-13' },
    { value: 'OBV', label: 'On-Balance Volume (OBV)' },
  ];

  const intervalOptions = intervals.map((iv) => ({
    value: iv,
    label: iv,
  }));

  const limitOptions = limits.map((value) => ({
    value: value,
    label: value,
  }));

  const tradingPairsOptions = tradingPairs.map((pair) => ({
    value: pair,
    label: pair,
  }));

  const handleIndicatorChange = (selectedOption) => {
    setIndicator(selectedOption.value);
  };

  const handleIntervalChange = (selectedOption) => {
    setInterval(selectedOption.value);
  };

  const handleSymbolChange = (selectedOption) => {
    setSymbol(selectedOption.value);
  };

  const handleLimitChange = (selectedOption) => {
    setLimit(selectedOption.value.toString());
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      backgroundColor: '#fff',
      borderColor: '#fff',
      color: '#000',
      fontSize: '18px',
      fontWeight: '500',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#000',
      fontSize: '18px',
      fontWeight: '500',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      color: '#000',
      borderColor: '#1c1c1c',
      fontSize: '18px',
      fontWeight: '500',
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#fff',
      borderRadius: '10px',
      borderColor: '#9b9b9b',
      color: '#000',
      height: '58px',
      marginBottom: '38px',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#000',
    }),
  };

  useEffect(() => {
    const fetchTradingPairs = async () => {
      const pairs = await getTradingPairs();
      setTradingPairs(pairs.sort());
      setSymbol('BTCUSDT');
    };

    fetchTradingPairs();
  }, []);

  return (
    <>
    <div className="indicator-card">
      <div className="dropdown-container">
        <label htmlFor="symbol-select">Choose a trading pair:</label>
        <Select
          classNamePrefix="custom-select"
          styles={customStyles}
          id="symbol-select"
          options={tradingPairsOptions}
          onChange={handleSymbolChange}
          value={tradingPairsOptions.find((option) => option.value === symbol)}
        />
      </div>
      <div className="dropdown-container">
        <label htmlFor="indicator-select">Choose an indicator:</label>
        <Select
          classNamePrefix="custom-select"
          styles={customStyles}
          id="indicator-select"
          options={indicatorOptions}
          onChange={handleIndicatorChange}
          defaultValue={indicatorOptions[0]}
        />
      </div>
      <div className="dropdown-container">
        <label htmlFor="interval-select">Choose a candle interval:</label>
        <Select
          classNamePrefix="custom-select"
          styles={customStyles}
          id="interval-select"
          options={intervalOptions}
          onChange={handleIntervalChange}
          defaultValue={intervalOptions[5]}
        />
      </div>
      {indicator === 'RSI' && (
        <div className="dropdown-container">
          <label htmlFor="limit-select">Choose number of candles to analyze:</label>
          <Select
            classNamePrefix="custom-select"
            styles={customStyles}
            id="limit-select"
            options={limitOptions}
            onChange={handleLimitChange}
            defaultValue={limitOptions[0]}
          />
        </div>
      )}
      {auth.token && (
      <div className="form-response">
        {indicator === 'SMA' && <BinanceSMA symbol={symbol} interval={interval} />}
        {indicator === 'RSI' && <BinanceRSI symbol={symbol} interval={interval} limit={limit} />}
        {indicator === 'EMA' && <BinanceEMA symbol={symbol} interval={interval} />}
        {indicator === 'OBV' && <BinanceOBV symbol={symbol} interval={interval} />}
      </div>
      )}

      {!auth.token && (
      <div className="form-response">
        {indicator === 'RSI' && <BinanceRSI symbol={symbol} interval={interval} limit={limit} />}
        {indicator === 'SMA' && 
          <button className="button-reg-0" onClick={() => openModal('register')} onKeyDown={(e) => handleKeyDown(e, 'register')} rel="noopener noreferrer">
          Register to access the SMA indicator
          </button>
        }
        {indicator === 'EMA' && 
          <button className="button-reg-0" onClick={() => openModal('register')} onKeyDown={(e) => handleKeyDown(e, 'register')} rel="noopener noreferrer">
          Register to access the EMA indicator
          </button>
        }
        {indicator === 'OBV' &&
          <button className="button-reg-0" onClick={() => openModal('register')} onKeyDown={(e) => handleKeyDown(e, 'register')} rel="noopener noreferrer">
          Register to use the OBV indicator
          </button>
        }
      </div>
      )}
    </div>
    <Modal isOpen={isModalOpen} onClose={closeModal} activeTab={activeTab} />
    
    </>
  );
};

export default Form;
