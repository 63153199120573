import React, { useContext, useEffect, useState } from 'react';
/* eslint-disable no-unused-vars */
import { compressToUTF16, decompressFromUTF16 } from 'lz-string';
import { AuthContext } from '../../../../components/AuthContext';
import Spinner from '../../../loading/Spinner';
import './PortfolioInitialization.css'
import CustomPieChart from '../portfolioCharts/PieChart';
import { API_BASE_URL } from '../../../../config';

const baseRatesUrl = `${API_BASE_URL}/public/exchange-rates`;
const xrplTokensUrl = `${API_BASE_URL}/xrpl/xrpl-tokens`;
const accountAssetsUrl = `${API_BASE_URL}/xrpl/check-account-assets`;

const PortfolioInitialization = ({ currencySymbol, onPortfolioDataChange }) => {
    const { auth } = useContext(AuthContext);
    const authToken = auth.token;
    const [ratesData, setRatesData] = useState([]);
    const [tokensData, setTokensData] = useState([]);
    const [accountAssets, setAccountAssets] = useState([]);
    const [searchSymbol, setSearchSymbol] = useState('');
    const [showZeroBalances, setShowZeroBalances] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [lastRatesUpdateTime, setLastRatesUpdateTime] = useState('');
    const [lastAssetsUpdateTime, setLastAssetsUpdateTime] = useState('');


    useEffect(() => {
        let isSubscribed = true;

        const fetchExchangeRates = async () => {
            if (!isSubscribed || !currencySymbol) return;

            const headers = { 'Authorization': `Bearer ${authToken}` };
            const ratesUrl = `${baseRatesUrl}/${currencySymbol}`;

            try {
                const response = await fetch(ratesUrl, { headers });
                // const data = await response.json();
                if (isSubscribed) {
                    const data = await response.json();
                    if (!data.rates[currencySymbol]) {
                        data.rates[currencySymbol] = 1;
                    }
                    const compressedData = compressToUTF16(JSON.stringify(data));
                    localStorage.setItem(currencySymbol + 'ExchangeRates', compressedData);
                    setRatesData(Object.entries(data.rates));
                    setLastRatesUpdateTime(new Date().toLocaleString()); // Set the last update time to now
                }
            } catch (error) {
                console.error(`Error fetching exchange rates for ${currencySymbol}:`, error);
            }
        };

        const fetchXrplTokens = async () => {
            const headers = { 'Authorization': `Bearer ${authToken}` };
        
            try {
                const response = await fetch(xrplTokensUrl, { headers });
                const data = await response.json();
                if (isSubscribed) {
                    const compressedData = compressToUTF16(JSON.stringify(data));
                    // console.log(data)
                    localStorage.setItem('xrplTokens', compressedData);
                    setTokensData(data);
                }
            } catch (error) {
                console.error('Error fetching XRPL Tokens:', error);
            }
        };

        const fetchAccountAssetsData = async () => {
            if (!isSubscribed || !auth.xrpAddress) return;

            const headers = { 'Authorization': `Bearer ${authToken}` };
            const params = new URLSearchParams({ xrpAddress: auth.xrpAddress });

            try {
                const response = await fetch(`${accountAssetsUrl}?${params}`, { headers });
                const data = await response.json();
                if (isSubscribed && data.accountAssets && Array.isArray(data.accountAssets)) {
                    setAccountAssets(data.accountAssets);
                    setLastAssetsUpdateTime(new Date().toLocaleString()); // Set the last update time to now
                }
            } catch (error) {
                console.error('Error fetching account assets:', error);
            }
        };

        if (authToken && currencySymbol) {
            setIsLoading(true); // Start loading
            fetchExchangeRates();
            fetchXrplTokens();
            fetchAccountAssetsData();
        }

         // Function to fetch all necessary data
         const fetchData = async () => {
            if (authToken && currencySymbol) {
                setIsLoading(true); // Start loading
                await fetchExchangeRates();
                await fetchXrplTokens();
                await fetchAccountAssetsData();
                setIsLoading(false); // Data is loaded, stop loading
            }
        };

        // Fetch data on component mount
        fetchData();

        // Set intervals for fetchExchangeRates and fetchAccountAssetsData
        const ratesIntervalId = setInterval(fetchExchangeRates, 10000);
        const assetsIntervalId = setInterval(fetchAccountAssetsData, 10000);

        // Clear intervals on component unmount or when dependencies change
        return () => {
            isSubscribed = false;
            clearInterval(ratesIntervalId);
            clearInterval(assetsIntervalId);
        };
    }, [authToken, currencySymbol, auth.xrpAddress]); 

    

    // ... useEffect for fetching data remains unchanged ...

    const mergeRatesWithTokens = () => {
        const tokenList = tokensData || [];
        return ratesData.map(([symbol, rate]) => {
            const token = tokenList.find(t => t.symbol === symbol);
            if (token && token.response) {
                return {
                    symbol,
                    rate,
                    issuer: token.response.issuer,
                    currency: token.response.currency
                };
            } else {
                return {
                    symbol,
                    rate,
                    issuer: 'N/A',
                    currency: 'N/A'
                };
            }
        });
    };

    const getSymbolForCurrency = (currencyHex) => {
        const token = tokensData.find(t => t.response.currency === currencyHex);
        return token ? token.symbol : null;
    };

    // Calculate and format the total sum before filtering
    const calculateTotalValue = (data) => {
        const totalSum = data.reduce((acc, { balance, rate }) => acc + (balance / rate), 0);
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 }).format(totalSum);
    };

    const enrichedData = mergeRatesWithTokens().map(({ symbol, rate, issuer, currency }) => {
        const asset = accountAssets.find(asset => getSymbolForCurrency(asset.currency) === symbol);
        return {
            symbol,
            rate: parseFloat(rate),
            issuer,
            currency,
            balance: asset ? parseFloat(asset.balance) : 0,
            accountCurrency: asset ? asset.currency : '-'
        };
    });

    // Save the enriched data to local storage on change
    useEffect(() => {
        const serializedData = JSON.stringify(enrichedData.map(({ balance, rate }) => ({ balance, rate })));
        localStorage.setItem('balanceRateData', compressToUTF16(serializedData));
    }, [enrichedData]);

    const formattedTotalSum = calculateTotalValue(enrichedData); // Use all data for total sum calculation

    // ... Remaining component logic ...

    // Sort the enriched data based on the product of balance and rate
    enrichedData.sort((a, b) => (b.balance / b.rate) - (a.balance / a.rate));

    // Filter the enriched data based on the searchSymbol and showZeroBalances
    const filteredEnrichedData = enrichedData.filter(({ symbol, balance }) =>
        symbol.toLowerCase().includes(searchSymbol.toLowerCase()) && 
        (showZeroBalances || balance !== 0)
    );

    useEffect(() => {
        if (ratesData.length > 0 && tokensData.length > 0 && accountAssets.length >= 0) {
            setIsLoading(false); // Data is loaded, stop loading
        }
    }, [ratesData, tokensData, accountAssets]); 

    const formatNumber = (num) => {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 }).format(num);
    };

    // Calculate the total sum of balance / rate
    const totalSum = filteredEnrichedData.reduce((acc, { balance, rate }) => acc + (balance / rate), 0);

    // Format the total sum
    // const formattedTotalSum = formatNumber(totalSum);
    
    // Continue from the part where you've filtered out assets with positive balance
    const positiveBalanceData = enrichedData.filter(asset => asset.balance > 0);

    // Calculate the total allocation for all assets to use for percentages
    const totalAllocation = positiveBalanceData.map(asset => ({
        symbol: asset.symbol,
        balance: asset.balance / asset.rate // Calculate allocation for each asset
    }));

    // If you want to calculate the percentage of each asset relative to the total
    const total = totalAllocation.reduce((acc, asset) => acc + asset.allocation, 0);

    const totalAllocationWithPercentage = totalAllocation.map(asset => ({
        ...asset,
        percentage: (asset.allocation / total) * 100 // Calculate percentage of total for each asset
    }));

    const currencySymbols = {
        'USD': '$',
        'EUR': '€',
        'GBP': '£',
        'JPY': '¥',
        'BTC': '₿',

      };


    return (
    <>
    <div className="portfolio-container">
        <div className="portfolio-header">
            <div className="portfolio-title">
                <p>Your Portfolio</p>
            </div>
            <h2>{currencySymbols[currencySymbol]}{formattedTotalSum}</h2>
            <div className="update-time">
                <p>Last Update: {lastAssetsUpdateTime}</p>
            </div>
        </div>
        <div className="pie-content">
            <CustomPieChart assetsData={totalAllocationWithPercentage} />

        </div>
        <div className="portfolio-content">
            <div className="asset-search">
            <input
                className="search-input"
                type="text"
                placeholder="Search symbol"
                value={searchSymbol}
                onChange={(e) => setSearchSymbol(e.target.value)}
            />
            <div className="balance-filter">
                <input
                type="checkbox"
                checked={showZeroBalances}
                onChange={() => setShowZeroBalances(!showZeroBalances)}
                />
                Show 0 Balances
            </div>
        </div>
        <div className="asset-table-container">
        {isLoading ? (
            <Spinner /> // Render your loading spinner here
        ) : (
                <table className="asset-table">
                    <thead>
                        <tr>
                            <th>Asset</th>
                            <th>Allocation %</th>
                            <th>Amount</th>
                            <th>Total</th>
                            <th>Rate</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
    {filteredEnrichedData.map(({ symbol, rate, issuer, currency, balance, accountCurrency }, index) => {
        // Calculate the asset's total value in the portfolio currency
        const assetTotalValue = balance / rate;
        // Calculate the percentage of the total portfolio value this asset represents
        // Ensure formattedTotalSum is converted back to a number correctly
        const allocationPercentage = (assetTotalValue / parseFloat(formattedTotalSum.replace(/[^0-9.-]+/g, ""))) * 100;

        // Integration of allocation percentage directly in JSX
        return (
            <tr key={index}>
                <td>{symbol}</td>
                <td>{formatNumber(allocationPercentage)}%</td>
                <td>{formatNumber(balance)}</td>
                <td>{formatNumber(assetTotalValue)} {currencySymbol}</td>
                <td>1 {currencySymbol} = {rate} {symbol}</td>
                <td>Buy / Sell</td>
                <td>...</td>
            </tr>
        );
    })}
</tbody>

                </table>
                )}
        </div>
        </div>
    </div>
    </>
);
};

export default PortfolioInitialization;