const calculateOBV = (data) => {
    if (data.length === 0) {
      return [];
    }
  
    const obv = [0];
  
    for (let i = 1; i < data.length; i++) {
      const prevObv = obv[i - 1];
      const currentVolume = data[i].volume;
      const change = data[i].close - data[i - 1].close;
  
      if (change > 0) {
        obv.push(prevObv + currentVolume);
      } else if (change < 0) {
        obv.push(prevObv - currentVolume);
      } else {
        obv.push(prevObv);
      }
    }
  
    return obv;
  };
  
  const calculateOBVSuccessRate = (obvValues, data) => {
    let successfulSignals = 0;
    let totalSignals = 0;
    const obvDetails = [];
  
    for (let i = 0; i < obvValues.length - 1; i++) {
      const obvDetail = {
        time: data[i + 1].time,
        close: data[i + 1].close,
        obv: obvValues[i],
        signal: null,
        success: false
      };
  
      if (obvValues[i] < obvValues[i + 1]) {
        totalSignals++;
        obvDetail.signal = 'Buy';
        if (data[i].close > data[i + 1].close) { // Check if data[i + 2] exists
          successfulSignals++;
          obvDetail.success = true;
        }
      } else if (obvValues[i] > obvValues[i + 1]) {
        totalSignals++;
        obvDetail.signal = 'Sell';
        if (data[i].close < data[i + 1].close) { // Check if data[i + 2] exists
          successfulSignals++;
          obvDetail.success = true;
        }
      }
      obvDetails.push(obvDetail);
    }
  
    const successRate = totalSignals ? (successfulSignals / totalSignals) * 100 : 0;
    return { obvDetails, successRate };
  };
  
  
  
  export { calculateOBV, calculateOBVSuccessRate };
  