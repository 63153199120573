import React, { useState } from 'react';
import './Faq.css';

const Faq = (props) => {
  const [expandedItems, setExpandedItems] = useState([0, 1, 2, 3, 4]);

  const toggleItem = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(expandedItems.filter((item) => item !== index));
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

  const renderArrow = (index) => {
    if (expandedItems.includes(index)) {
      return <span>&#9660;</span>; // Down arrow icon
    } else {
      return <span>&#9650;</span>; // Up arrow icon
    }
  };

  return (
    <div className="faq-container">
      <h2 className="faq-title">FAQ</h2>
      <div className="faq-item">
        <h2 className="faq-question" onClick={() => toggleItem(0)}>
          {renderArrow(0)} What is the purpose of a trading tool like ISR?
        </h2>
        {expandedItems.includes(0) && (
          <p className="faq-answer">
            ISR is a trading tool designed to help traders and investors evaluate the performance and success rates of various technical indicators for different trading pairs and time intervals. By providing an intuitive interface, this analysis tool aims to help users make informed decisions based on historical data and indicator performance when trading stocks, cryptocurrencies, or other assets.
          </p>
        )}
      </div>

      <div className="faq-item">
        <h2 className="faq-question" onClick={() => toggleItem(1)}>
          {renderArrow(1)} How does a trading analysis tool like ISR calculate the success rate for an indicator?
        </h2>
        {expandedItems.includes(1) && (
          <p className="faq-answer">
            ISR calculates the success rate for an indicator by analyzing its historical performance in predicting market movements. It evaluates how accurately the indicator identified profitable entry and exit points over a given period. The success rate is expressed as the percentage of correctly predicted outcomes out of the total occurrences.
          </p>
        )}
      </div>

      <div className="faq-item">
        <h2 className="faq-question" onClick={() => toggleItem(2)}>
          {renderArrow(2)} Which technical indicators does a trading analysis tool like ISR support?
        </h2>
        {expandedItems.includes(2) && (
          <>
          <p className="faq-answer">
            Currently, ISR supports the following technical indicators:
          </p>
            <ol>
              <li>Relative Strength Index (RSI)</li>
              <li>Simple Moving Average (SMA)</li>
              <li>Exponential Moving Average (EMA) 5-8-13</li>
              <li>On-Balance Volume (OBV)</li>
            </ol>
          <p className="faq-answer">
            The platform plans to add more indicators in the future to provide a comprehensive range of options for its users.
          </p>
          </>
        )}
      </div>

      <div className="faq-item">
        <h2 className="faq-question" onClick={() => toggleItem(3)}>
          {renderArrow(3)} Can I customize the parameters for the technical indicators in a trading analysis tool like ISR?
        </h2>
        {expandedItems.includes(3) && (
          <p className="faq-answer">
            Currently, ISR offers a fixed set of parameters for each technical indicator. However, the developers understand the need for customization and are working on implementing user-defined settings to allow you to choose your preferred parameters for a more tailored trading analysis experience.
          </p>
        )}
      </div>

      <div className="faq-item">
        <h2 className="faq-question" onClick={() => toggleItem(4)}>
          {renderArrow(4)} Is a trading analysis tool like ISR free to use?
        </h2>
        {expandedItems.includes(4) && (
          <p className="faq-answer">
            ISR offers a free plan for beginners, ensuring access to essential tools and resources. For those looking to advance, the Earlybird plan is competitively priced at just 19 XRP per month, providing additional value. Future enhancements may introduce new features, but the team will do its best to keep the basic features available for free."
          </p>
        )}
      </div>
    </div>
  );
}

export default Faq;
