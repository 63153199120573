import React, { useState, useEffect } from 'react';
import { getBinanceData } from '../../../api/binance';
import { calculateOBV, calculateOBVSuccessRate } from './obvCalculator';
import DetailsOBV from './DetailsOBV';

const BinanceOBV = ({ symbol, interval }) => {
  const [obvDetails, setObvDetails] = useState([]);
  const [successRate, setSuccessRate] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const data = await getBinanceData(symbol, interval);
    const obv = calculateOBV(data);
    const { obvDetails, successRate } = calculateOBVSuccessRate(obv, data);
    setObvDetails(obvDetails);
    setSuccessRate(successRate);
    setLoading(false);
  };
  

  useEffect(() => {
    // Removed fetchData call from here
  }, [symbol, interval]);

  const handleButtonClick = () => {
    fetchData();
    setButtonClicked(true);
  };

  return (
    <div className="binance-obv">
      <button onClick={handleButtonClick}>Calculate Success Rate OBV</button>
      {buttonClicked && !loading && (
        <>
          {successRate !== null && symbol !== null && (
            <h3><strong>Success Rate: {successRate.toFixed(2)}%</strong></h3>
          )}
          <DetailsOBV obvDetails={obvDetails} />
        </>
      )}
    </div>
  );
};

export default BinanceOBV;
