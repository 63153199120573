import React, { useState, useEffect } from 'react';

import {calculateSuccessProbability, calculateCrossoverSignals, calculateSMA } from './smaCalculator';
import { getBinanceData } from '../../../api/binance';

const BinanceSMA = (props) => {
  const [symbol, setSymbol] = useState(props.symbol);
  const [interval, setInterval] = useState(props.interval);
  const [successProbability, setSuccessProbability] = useState(null);

  useEffect(() => {
    setSymbol(props.symbol);
    setInterval(props.interval);
  }, [props.symbol, props.interval]);

  const fetchData = async () => {
    const data = await getBinanceData(symbol, interval);
    const shortSma = calculateSMA(data, 20);
    const longSma = calculateSMA(data, 50);
    const signals = calculateCrossoverSignals(data, shortSma, longSma);
    const probability = calculateSuccessProbability(signals);
    setSuccessProbability(probability);

  };

  return (
    <div>
      <button onClick={fetchData}>Calculate Success Rate SMA</button>
      {successProbability !== null && symbol !== null && <p><strong>Success Rate: {(successProbability * 100).toFixed(2)}%</strong></p>}
    </div>
  );
};

export default BinanceSMA;
