import React, { useContext, useState } from 'react';
import { AuthContext } from '../AuthContext';
import PortfolioInitialization from './dashboardPortfolio/portfolioInitialization/portfolioInitialization';
import FiatSwitcher from './fiatSwitcher/FiatSwitcher';
import ModalComponent from '../modals/ModalComponent';
import './Dashboard.css'

const Dashboard = () => {
    const { auth } = useContext(AuthContext);
    /* eslint-disable no-unused-vars */
    const authToken = auth.token;
    /* eslint-disable no-unused-vars */
    const [portfolioData, setPortfolioData] = useState([]);
    const availableCurrencies = ['USD', 'EUR', 'GBP', 'JPY', 'BTC', 'LTC', 'ETH']; 
    const [currencySymbol, setCurrencySymbol] = useState(availableCurrencies[0]);
    const handleCurrencyChange = newCurrency => {
        setCurrencySymbol(newCurrency);
    };

    return (
        <>
        <div className='dashboard-container'>
            <div className='column-left'>
                <h2>Your portfolio XRP address</h2>
                <a href={`https://testnet.xrpl.org/accounts/${auth.xrpAddress}`} target="_blank" rel="noopener noreferrer">
                  {auth.xrpAddress}
                </a>


            </div>
            <div  className='column-right'>
                <div className='head-container'>
                    <div className='column-one'>
                        <ModalComponent/>
                        <FiatSwitcher
                            currencySymbol={currencySymbol}
                            onCurrencyChange={handleCurrencyChange}
                            availableCurrencies={availableCurrencies}
                            />
                    </div>
                </div>
                <PortfolioInitialization
                    currencySymbol={currencySymbol}
                    onPortfolioDataChange={setPortfolioData}
                    />
            </div>
        </div>
        </>
    );
};

export default Dashboard;
