import React, { useState } from 'react';
import axios from 'axios';
import './ForgotPassword.css'
import { API_BASE_URL } from '../../../config';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_BASE_URL}/user/forgot-password`, { email });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Error during password reset. Please try again.');
    }
  };

  return (
  
      <form className='forgot-form' onSubmit={handleFormSubmit}>
        <h2>Forgot Password</h2>
        <input className='forgot-input' type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <button className='forgot-button' type="submit">Reset Password</button>
        {message && <p>{message}</p>}
      </form>
  );
}

export default ForgotPassword;