import React from 'react';
import './Documentation.css';

// Documentation component for API endpoints, including parameters and code examples
const ApiDocumentation = () => {
  // Define the documentation data with expanded details
  const documentationData = [
    {
      endpoint: '/st-deviation/:platform',
      method: 'GET',
      description: 'Calculates the standard deviation of price for a given market pair on the specified platform. Standard deviation measures the price volatility or the dispersion of prices from the average. Platforms available: binance, kucoin, kraken, stocks.',
      parameters: [
        { name: 'symbol', description: 'The market pair under analysis' },
        { name: 'interval', description: 'Type of candlestick patterns: 1min, 3min, 5min, 15min, 30min, 1hour, 2hour, 4hour, 6hour, 8hour, 12hour, 1day, 1week' }
      ],
      exampleResponse: "{'standardDeviation': {'standardDeviation': 122.3566814352203, 'timestamp': 1711887643997}}",
      exampleCode: `
const url = new URL('https://rest.indicatorsuccessrate.com/api/indicators/st-deviation/binance');
url.searchParams.append('symbol', 'BTCUSDT');
url.searchParams.append('interval', '1h');

fetch(url, {
  method: 'GET',
  headers: {
    'x-api-key': 'YOUR_API_KEY_HERE'
  }
})
  .then(response => response.json())
  .then(data => console.log(data));`
    },
    {
      endpoint: '/rsi-v2',
      method: 'GET',
      description: 'Computes the Relative Strength Index (RSI) for a given market pair. RSI is a momentum oscillator that measures the speed and change of price movements, often used to identify overbought or oversold conditions.',
      parameters: [
        { name: 'symbol', description: 'The market pair under analysis' },
        { name: 'limit', description: 'Number of periods to calculate RSI.' },
        { name: 'interval', description: 'Type of candlestick patterns: 1min, 3min, 5min, 15min, 30min, 1hour, 2hour, 4hour, 6hour, 8hour, 12hour, 1day, 1week' }
      ],
      exampleResponse: "{'timestamp': 1711884060000, 'open': 70432.7, 'close': 70420, 'high': 70432.8, 'low': 70420, 'volume': 0.62848381, 'turnover': 44265.515542443, 'rsi': 68.1552612473}",
      exampleCode: `
const url = new URL('https://rest.indicatorsuccessrate.com/api/indicators/rsi-v2');
url.searchParams.append('symbol', 'BTCUSDT');
url.searchParams.append('limit', '500');
url.searchParams.append('interval', '1min');

fetch(url, {
  method: 'GET',
  headers: {
    'x-api-key': 'YOUR_API_KEY_HERE'
  }
})
  .then(response => response.json())
  .then(data => console.log(data));`
    },
    {
      endpoint: '/atr-v2',
      method: 'GET',
      description: 'Determines the Average True Range (ATR) for a given market pair. ATR is a technical analysis indicator that measures market volatility by decomposing the entire range of an asset price for that period.',
      parameters: [
        { name: 'symbol', description: 'The market pair under analysis' },
        { name: 'limit', description: 'Number of periods to calculate ATR.' },
        { name: 'interval', description: 'Type of candlestick patterns: 1min, 3min, 5min, 15min, 30min, 1hour, 2hour, 4hour, 6hour, 8hour, 12hour, 1day, 1week' }
      ],
      exampleResponse: "{'timestamp': 1711884060000, 'open': 70432.7, 'close': 70420, 'high': 70432.8, 'low': 70420, 'volume': 0.62848381, 'turnover': 44265.515542443, 'atr': 68.1552612473}",
      exampleCode: `
const url = new URL('https://rest.indicatorsuccessrate.com/api/indicators/atr-v2');
url.searchParams.append('symbol', 'BTCUSDT');
url.searchParams.append('limit', '500');
url.searchParams.append('interval', '1min');

fetch(url, {
  method: 'GET',
  headers: {
    'x-api-key': 'YOUR_API_KEY_HERE'
  }
})
  .then(response => response.json())
  .then(data => console.log(data));`
    },
    {
      endpoint: '/ema-v2',
      method: 'GET',
      description: 'Calculates the Exponential Moving Average (EMA) for a given market pair. EMA is a type of moving average that places a greater weight and significance on the most recent data points.',
      parameters: [
        { name: 'symbol', description: 'The market pair under analysis' },
        { name: 'limit', description: 'Number of periods to calculate EMA.' },
        { name: 'interval', description: 'Type of candlestick patterns: 1min, 3min, 5min, 15min, 30min, 1hour, 2hour, 4hour, 6hour, 8hour, 12hour, 1day, 1week' }
      ],
      exampleResponse: "{'timestamp': 1711884060000, 'open': 70432.7, 'close': 70424.7, 'high': 70432.8, 'low': 70424.6, 'volume': 0.60493514, 'turnover': 42607.194282187, 'ema_5': 70423.1900201684, 'ema_10': 70408.2563102642, 'ema_12': 70402.0644214507, 'ema_26': 70367.0078678324, 'ema_50': 70334.2234942203, 'ema_90': 70311.0888323212, 'atr': 15.0069873464, 'EMA_diff_12_26': 35.0565536183, 'EMA_diff_5_50': 88.9665259481, 'EMA_diff_12_26_roc': -0.01405686, 'EMA_diff_5_50_roc': -0.0319672066, 'EMA_trend_5_50': 'Bullish', 'EMA_crossover_5_50': 'Bullish Crossover', 'EMA_trend_strength_12_26': 'Weak', 'EMA_trend_strength_5_50': 'Weak', 'EMA_final_trend': 'PotentialBullish'}",
      exampleCode: `
const url = new URL('https://rest.indicatorsuccessrate.com/api/indicators/ema-v2');
url.searchParams.append('symbol', 'BTCUSDT');
url.searchParams.append('limit', '500');
url.searchParams.append('interval', '1min');

fetch(url, {
  method: 'GET',
  headers: {
    'x-api-key': 'YOUR_API_KEY_HERE'
  }
})
  .then(response => response.json())
  .then(data => console.log(data));`
    },
    {
      endpoint: '/sma-v2',
      method: 'GET',
      description: 'Calculates the Simple Moving Average (SMA) for a given market pair. SMA is an arithmetic moving average calculated by adding recent closing prices and then dividing that by the number of time periods in the calculation average.',
      parameters: [
        { name: 'symbol', description: 'The market pair under analysis' },
        { name: 'limit', description: 'Number of periods to calculate SMA.' },
        { name: 'interval', description: 'Type of candlestick patterns: 1min, 3min, 5min, 15min, 30min, 1hour, 2hour, 4hour, 6hour, 8hour, 12hour, 1day, 1week' }
      ],
      exampleResponse: "{'timestamp': 1712568480000, 'open': 72530, 'close': 72605.1, 'high': 72605.1, 'low': 72501.2, 'volume': 13.8721576, 'turnover': 1006641.659475529, 'sma_5': 72360.82, 'sma_10': 72243.96, 'sma_20': 72073.2, 'sma_50': 71791.984, 'sma_100': 71408.322}",
      exampleCode: `
const url = new URL('https://rest.indicatorsuccessrate.com/api/indicators/sma-v2');
url.searchParams.append('symbol', 'BTCUSDT');
url.searchParams.append('limit', '500');
url.searchParams.append('interval', '1min');

fetch(url, {
  method: 'GET',
  headers: {
    'x-api-key': 'YOUR_API_KEY_HERE'
  }
})
  .then(response => response.json())
  .then(data => console.log(data));`
    },
    {
      endpoint: '/wma-v2',
      method: 'GET',
      description: 'Computes the Weighted Moving Average (WMA) for a given market pair. WMA is a type of moving average that gives more importance to recent data and less importance to past data.',
      parameters: [
        { name: 'symbol', description: 'The market pair under analysis' },
        { name: 'limit', description: 'Number of periods to calculate WMA.' },
        { name: 'interval', description: 'Type of candlestick patterns: 1min, 3min, 5min, 15min, 30min, 1hour, 2hour, 4hour, 6hour, 8hour, 12hour, 1day, 1week' }
      ],
      exampleResponse: "{'timestamp': 1712568540000, 'open': 72429.8, 'close': 72406.2, 'high': 72429.8, 'low': 72406.2, 'volume': 0.13140003, 'turnover': 9515.187669812, 'wma_5': 72403.4333333333, 'wma_10': 72325.8636363636, 'wma_20': 72199.2933333333, 'wma_50': 71995.5708235294, 'wma_100': 71688.8807326733}",
      exampleCode: `
const url = new URL('https://rest.indicatorsuccessrate.com/api/indicators/wma-v2');
url.searchParams.append('symbol', 'BTCUSDT');
url.searchParams.append('limit', '500');
url.searchParams.append('interval', '1min');

fetch(url, {
  method: 'GET',
  headers: {
    'x-api-key': 'YOUR_API_KEY_HERE'
  }
})
  .then(response => response.json())
  .then(data => console.log(data));`
    },
    {
      endpoint: '/dema-v2',
      method: 'GET',
      description: 'Calculates the Double Exponential Moving Average (DEMA) for a given market pair. DEMA is a smoother and faster moving average designed to reduce the lag inherent in traditional moving averages.',
      parameters: [
        { name: 'symbol', description: 'The market pair under analysis' },
        { name: 'limit', description: 'Number of periods to calculate DEMA.' },
        { name: 'interval', description: 'Type of candlestick patterns: 1min, 3min, 5min, 15min, 30min, 1hour, 2hour, 4hour, 6hour, 8hour, 12hour, 1day, 1week' }
      ],
      exampleResponse: "{'timestamp': 1712568540000, 'open': 72429.8, 'close': 72333.6, 'high': 72430.1, 'low': 72333.6, 'volume': 1.68426216, 'turnover': 121912.349445449, 'dema_5': 72406.3882379574, 'dema_10': 72399.4875992052, 'dema_12': 72387.3069049053, 'dema_20': 72347.0412225958, 'dema_30': 72317.938755953, 'dema_50': 72274.918005655, 'dema_100': 72140.625901383, 'dema_200': 71742.5404654582}",
      exampleCode: `
const url = new URL('https://rest.indicatorsuccessrate.com/api/indicators/dema-v2');
url.searchParams.append('symbol', 'BTCUSDT');
url.searchParams.append('limit', '500');
url.searchParams.append('interval', '1min');

fetch(url, {
  method: 'GET',
  headers: {
    'x-api-key': 'YOUR_API_KEY_HERE'
  }
})
  .then(response => response.json())
  .then(data => console.log(data));`
    },
    {
      endpoint: '/tema-v2',
      method: 'GET',
      description: 'Computes the Triple Exponential Moving Average (TEMA) for a given market pair. TEMA is designed to smooth price fluctuations, making it easier to identify the trend direction over time.',
      parameters: [
        { name: 'symbol', description: 'The market pair under analysis' },
        { name: 'limit', description: 'Number of periods to calculate TEMA.' },
        { name: 'interval', description: 'Type of candlestick patterns: 1min, 3min, 5min, 15min, 30min, 1hour, 2hour, 4hour, 6hour, 8hour, 12hour, 1day, 1week' }
      ],
      exampleResponse: "{'timestamp': 1712568540000, 'open': 72429.8, 'close': 72360.3, 'high': 72475.8, 'low': 72325, 'volume': 3.91502105, 'turnover': 283502.087343048, 'tema_5': 72411.9992029211, 'tema_10': 72437.2812305825, 'tema_12': 72432.1123170665, 'tema_20': 72389.1845872441, 'tema_30': 72358.2461726962, 'tema_50': 72327.8112548077, 'tema_100': 72313.135928397, 'tema_200': 72140.806195946}",
      exampleCode: `
const url = new URL('https://rest.indicatorsuccessrate.com/api/indicators/tema-v2');
url.searchParams.append('symbol', 'BTCUSDT');
url.searchParams.append('limit', '500');
url.searchParams.append('interval', '1min');

fetch(url, {
  method: 'GET',
  headers: {
    'x-api-key': 'YOUR_API_KEY_HERE'
  }
})
  .then(response => response.json())
  .then(data => console.log(data));`
    },
    {
      endpoint: '/kama-v2',
      method: 'GET',
      description: "Calculates Kaufman's Adaptive Moving Average (KAMA) for a given market pair. KAMA adjusts its sensitivity to price fluctuations, allowing it to be more responsive in trending markets and less responsive in ranging markets.",
      parameters: [
        { name: 'symbol', description: 'The market pair under analysis' },
        { name: 'limit', description: 'Number of periods to calculate KAMA.' },
        { name: 'interval', description: 'Type of candlestick patterns: 1min, 3min, 5min, 15min, 30min, 1hour, 2hour, 4hour, 6hour, 8hour, 12hour, 1day, 1week' }
      ],
      exampleResponse: "{'timestamp': 1712568600000, 'open': 72357.7, 'close': 72343.1, 'high': 72373.5, 'low': 72338.7, 'volume': 0.24261653, 'turnover': 17554.397663825, 'kama_5': 72360.818372608, 'kama_10': 72360.8173126903, 'kama_12': 72360.8169432691, 'kama_20': 72360.8159185142, 'kama_30': 72360.8181988147, 'kama_50': 72360.8181988146, 'kama_100': 72360.8181988146, 'kama_200': 72360.8181988146}",
      exampleCode: `
const url = new URL('https://rest.indicatorsuccessrate.com/api/indicators/kama-v2');
url.searchParams.append('symbol', 'BTCUSDT');
url.searchParams.append('limit', '500');
url.searchParams.append('interval', '1min');

fetch(url, {
  method: 'GET',
  headers: {
    'x-api-key': 'YOUR_API_KEY_HERE'
  }
})
  .then(response => response.json())
  .then(data => console.log(data));`
    },
    {
      endpoint: '/vwap-v2',
      method: 'GET',
      description: 'Determines the Volume Weighted Average Price (VWAP) for a given market pair. VWAP gives an average price a security has traded at throughout the day, based on both volume and price. It is often used as a trading benchmark.',
      parameters: [
        { name: 'symbol', description: 'The market pair under analysis' },
        { name: 'limit', description: 'Number of periods to calculate VWAP.' },
        { name: 'interval', description: 'Type of candlestick patterns: 1min, 3min, 5min, 15min, 30min, 1hour, 2hour, 4hour, 6hour, 8hour, 12hour, 1day, 1week' }
      ],
      exampleResponse: "{'timestamp': 1712568600000, 'open': 72357.7, 'close': 72442.4, 'high': 72467.9, 'low': 72308.3, 'volume': 1.17176214, 'turnover': 84827.958134943, 'typical_price': 72406.2, 'tpv': 84842.843861268, 'cum_tpv': 141404986.47592878, 'cum_volume': 2011.41857405, 'vwap': 70301.1239431927}",
      exampleCode: `
const url = new URL('https://rest.indicatorsuccessrate.com/api/indicators/vwap-v2');
url.searchParams.append('symbol', 'BTCUSDT');
url.searchParams.append('limit', '500');
url.searchParams.append('interval', '1min');

fetch(url, {
  method: 'GET',
  headers: {
    'x-api-key': 'YOUR_API_KEY_HERE'
  }
})
  .then(response => response.json())
  .then(data => console.log(data));`
    },
    {
      endpoint: '/candles-type',
      method: 'GET',
      description: 'Identifies the candlestick type for a given market pair. This endpoint analyzes the shape and characteristics of the candlestick to classify it into various types, such as Doji, Hammer, or Engulfing patterns, providing insights into market sentiment.',
      parameters: [
        { name: 'symbol', description: 'The market pair under analysis' },
        { name: 'limit', description: 'Number of periods to calculate candlestick types.' },
        { name: 'interval', description: 'Type of candlestick patterns: 1min, 3min, 5min, 15min, 30min, 1hour, 2hour, 4hour, 6hour, 8hour, 12hour, 1day, 1week' }
      ],
      exampleResponse: "{'timestamp': 1712568600000, 'open': 72357.7, 'close': 72414.4, 'high': 72467.9, 'low': 72308.3, 'volume': 2.6304932, 'turnover': 190462.799631206, 'Candle_Type': 'Spinning Top', 'Description': 'Represents a session with indecision where neither buyers nor sellers could gain the upper hand.', 'candle_sentiment': 'Neutral'}",
      exampleCode: `
const url = new URL('https://rest.indicatorsuccessrate.com/api/indicators/candles-type');
url.searchParams.append('symbol', 'BTCUSDT');
url.searchParams.append('limit', '500');
url.searchParams.append('interval', '1min');

fetch(url, {
  method: 'GET',
  headers: {
    'x-api-key': 'YOUR_API_KEY_HERE'
  }
})
  .then(response => response.json())
  .then(data => console.log(data));`
    },
    {
      endpoint: '/two-candles-pattern',
      method: 'GET',
      description: 'Analyzes and identifies patterns formed by two consecutive candlesticks for a given market pair. This can reveal potential market movements based on historical candlestick pattern formations.',
      parameters: [
        { name: 'symbol', description: 'The market pair under analysis' },
        { name: 'limit', description: 'Number of periods to calculate patterns.' },
        { name: 'interval', description: 'Type of candlestick patterns: 1min, 3min, 5min, 15min, 30min, 1hour, 2hour, 4hour, 6hour, 8hour, 12hour, 1day, 1week' }
      ],
      exampleResponse: "{'timestamp': 1712568660000, 'open': 72383, 'close': 72308, 'high': 72415.8, 'low': 72308, 'volume': 2.75403509, 'turnover': 199260.1820118, 'two_candles_patterns': 'NoPattern'}",
      exampleCode: `
const url = new URL('https://rest.indicatorsuccessrate.com/api/indicators/two-candles-pattern');
url.searchParams.append('symbol', 'BTCUSDT');
url.searchParams.append('limit', '500');
url.searchParams.append('interval', '1min');

fetch(url, {
  method: 'GET',
  headers: {
    'x-api-key': 'YOUR_API_KEY_HERE'
  }
})
  .then(response => response.json())
  .then(data => console.log(data));`
    },
    {
      endpoint: '/three-candles-pattern',
      method: 'GET',
      description: 'Evaluates patterns formed by three consecutive candlesticks for a given market pair. Similar to the two candles pattern analysis, this provides deeper insight into market trends and potential reversals.',
      parameters: [
        { name: 'symbol', description: 'The market pair under analysis' },
        { name: 'limit', description: 'Number of periods to calculate patterns.' },
        { name: 'interval', description: 'Type of candlestick patterns: 1min, 3min, 5min, 15min, 30min, 1hour, 2hour, 4hour, 6hour, 8hour, 12hour, 1day, 1week' }
      ],
      exampleResponse: "{'timestamp': 1712568660000, 'open': 72383, 'close': 72297, 'high': 72415.8, 'low': 72273.5, 'volume': 4.14617467, 'turnover': 299912.558915168, 'three_candles_patterns': 'NoPattern'}",
      exampleCode: `
const url = new URL('https://rest.indicatorsuccessrate.com/api/indicators/three-candles-pattern');
url.searchParams.append('symbol', 'BTCUSDT');
url.searchParams.append('limit', '500');
url.searchParams.append('interval', '1min');

fetch(url, {
  method: 'GET',
  headers: {
    'x-api-key': 'YOUR_API_KEY_HERE'
  }
})
  .then(response => response.json())
  .then(data => console.log(data));`
    },
    {
      endpoint: '/complex-candles-pattern',
      method: 'GET',
      description: 'Analyzes more intricate candlestick patterns that involve multiple candlesticks, for a given market pair. This endpoint is designed to detect complex formations that may indicate significant market events or sentiment shifts.',
      parameters: [
        { name: 'symbol', description: 'The market pair under analysis' },
        { name: 'limit', description: 'Number of periods to calculate patterns.' },
        { name: 'interval', description: 'Type of candlestick patterns: 1min, 3min, 5min, 15min, 30min, 1hour, 2hour, 4hour, 6hour, 8hour, 12hour, 1day, 1week' }
      ],
      exampleResponse: "{'timestamp': 1712568720000, 'open': 72289.4, 'close': 72334.3, 'high': 72334.3, 'low': 72289.4, 'volume': 0.19938614, 'turnover': 14417.233757269, 'complex_candles_patterns': 'NoPattern'}",
      exampleCode: `
const url = new URL('https://rest.indicatorsuccessrate.com/api/indicators/complex-candles-pattern');
url.searchParams.append('symbol', 'BTCUSDT');
url.searchParams.append('limit', '500');
url.searchParams.append('interval', '1min');

fetch(url, {
  method: 'GET',
  headers: {
    'x-api-key': 'YOUR_API_KEY_HERE'
  }
})
  .then(response => response.json())
  .then(data => console.log(data));`
    }
  ];

  // Render the documentation
  return (
    <div className="apiDocumentation">
      <h2 className="documentationTitle">API Documentation</h2>
      <div className="documentationSection">
        <p>For all ISR users, this is the canonical Base URL:</p>
        <p><strong>Base URL:</strong><br/> <a href="https://rest.indicatorsuccessrate.com/api/indicators">https://rest.indicatorsuccessrate.com/api/indicators</a></p>
        <br/>
        <p>_______________________</p>
        <p><strong>If you are using Dhali,</strong> then you should use the following Base URL:</p>
        <p><strong>Base URL (for Dhali users only):</strong><br/> <a href="https://run.api.dhali.io/39d1c91c-750b-4294-94fb-69e1b74c1162">https://run.api.dhali.io/39d1c91c-750b-4294-94fb-69e1b74c1162</a></p>
      </div>
      {documentationData.map((doc, index) => (
        <div key={index} className="documentationSection">
          <h2 className="endpointTitle">{doc.endpoint}</h2>
          <p className="method"><strong>Method:</strong> {doc.method}</p>
          <p className="description"><strong>Description:</strong> {doc.description}</p>
          {doc.parameters.length > 0 && (
            <>
              <p className="parametersTitle"><strong>Parameters:</strong></p>
              <ul className="parametersList">
                {doc.parameters.map((param, paramIndex) => (
                  <li key={paramIndex} className="parameterItem">
                    <strong>{param.name}:</strong> {param.description}
                  </li>
                ))}
              </ul>
            </>
          )}
          <p className="exampleResponseTitle"><strong>Example Response:</strong></p>
          <pre className="exampleResponse">{doc.exampleResponse}</pre>
          <p className="exampleCodeTitle"><strong>Example Code:</strong></p>
          <pre className="exampleCode">{doc.exampleCode.trim()}</pre>
        </div>
      ))}
    </div>
  );
}

export default ApiDocumentation;
