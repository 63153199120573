import React from 'react';
import './sectionColums.css';
import PhotoCard from '../photoCard/PhotoCard';

import second from '../../../images/second.png';
import xumm from '../../../images/xumm.jpeg';
import fourth from '../../../images/fourth.png';
import xrpl from '../../../images/xrpl1.jpeg'

const sectionColums = () => {

  return (
    <div className="column-section-container">
      <div className="column-content-container">
        <div className="column-one-container">
          <PhotoCard 
            title="Revolutionizing Trading Strategies with ISR's XRPL Integration" 
            subtitle="Enhancing Security and Transparency in Financial Market Analysis." 
            coverImage={xrpl}
            link='/blog/revolutionizing-trading-strategies-with-isr-xrpl-integration'
          />

          <PhotoCard 
            title="Mastering the Markets" 
            subtitle="An Introduction to Technical Analysis in Cryptocurrency, Stock, and Forex Trading." 
            coverImage={second}
            link='/blog/mastering-the-markets'
          />
        </div>

        <div className="column-two">
          <PhotoCard 
            title="Revolutionizing Payments and Authentication" 
            subtitle="XUMM Integration at Indicator Success Rate."  
            coverImage={xumm}
            link='/blog/revolutionizing-payments-authentication-xumm-integration'
          />
          
          <PhotoCard 
            title="Unleashing the Power of Trading: ISR API" 
            subtitle="A Comprehensive Guide to Integrating Indicator Success Rate in Your Trading Strategies" 
            coverImage={fourth}
            link='/blog/unleashing-the-power-of-trading-isr-api'
          />
        </div>
      </div>
    </div>
  );
};

export default sectionColums;
