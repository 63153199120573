const calculateRSI = (data, period) => {
  if (data.length < period) {
    return [];
  }

  const rsi = [];
  const gains = [];
  const losses = [];

  for (let i = 1; i < data.length; i++) {
    const change = data[i - 1].close - data[i].close; // Reverse the calculation order
    gains.push(Math.max(change, 0));
    losses.push(Math.max(-change, 0));
  }

  let avgGain = gains.slice(0, period).reduce((acc, val) => acc + val) / period;
  let avgLoss = losses.slice(0, period).reduce((acc, val) => acc + val) / period;

  for (let i = period; i <= data.length; i++) {
    if (i > period) {
      avgGain = ((avgGain * (period - 1)) + gains[i - 2]) / period; // Adjust the index for gains
      avgLoss = ((avgLoss * (period - 1)) + losses[i - 2]) / period; // Adjust the index for losses
    }

    if (avgLoss === 0) {
      rsi.push(100);
    } else {
      const rs = avgGain / avgLoss;
      rsi.push(100 - (100 / (1 + rs)));
    }
  }

  return rsi;
};

const calculateRSISuccessRate = (rsiValues, data, oversoldThreshold = 30, overboughtThreshold = 70) => {
  let successfulSignals = 0;
  let totalSignals = 0;
  const rsiDetails = [];

  for (let i = rsiValues.length - 1; i >= 1; i--) { // Update the loop condition to iterate backward
    const rsiDetail = {
      time: data[i].time, // Use the corresponding data[i - 1].time
      close: data[i].close, // Use the corresponding data[i - 1].close
      rsi: rsiValues[i],
      signal: null,
      success: false
    };

    if (rsiValues[i] <= oversoldThreshold) {
      totalSignals++;
      rsiDetail.signal = 'Buy';
      if (data[i].close < data[i - 1].close) { // Compare close prices of successive rows
        successfulSignals++;
        rsiDetail.success = true;
      }
    } else if (rsiValues[i] >= overboughtThreshold) {
      totalSignals++;
      rsiDetail.signal = 'Sell';
      if (data[i].close > data[i - 1].close) { // Compare close prices of successive rows
        successfulSignals++;
        rsiDetail.success = true;
      }
    }
    rsiDetails.push(rsiDetail);
  }

  const successRate = totalSignals ? (successfulSignals / totalSignals) * 100 : 0;
  return { rsiDetails, successRate };
};


module.exports = { calculateRSI, calculateRSISuccessRate };
