import React, { useState } from 'react';
import './Register.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from '../../../config';

function Register({ onRegistrationComplete }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const register = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(null);

    // Email validation
    const emailValidationRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z|a-z]{2,}$/i;
    if (!email || !emailValidationRegex.test(email)) {
      setLoading(false);
      return setErrorMessage('Please enter a valid email address');
    }

    if (!password || !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(password)) {
      setLoading(false);
      return setErrorMessage('Password must contain at least 1 lowercase, 1 uppercase, 1 numeric, 1 special char, and be at least 8 chars long');
    }

    try {
      await axios.post(`${API_BASE_URL}/user/register`, { email, password });
      setEmail('');
      setPassword('');
      if (onRegistrationComplete) {
        onRegistrationComplete(); // Call the callback function to close the modal
      }
    } catch (err) {
      if (err.response && err.response.status === 400 && err.response.data.error) {
        setErrorMessage(err.response.data.error);
      } else {
        console.log(err); // Optionally remove this line if you don't want to log the error
        setErrorMessage('An error occurred. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <form className='register-form' onSubmit={register}>
      {errorMessage && <p className="error">{errorMessage}</p>}
      <input className="register-input" type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
      
      <div className="password-container">
        <input
          className="register-input"
          type={passwordVisible ? "text" : "password"}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <FontAwesomeIcon
          icon={passwordVisible ? faEyeSlash : faEye}
          onClick={togglePasswordVisibility}
          className="password-toggle-icon"
        />
      </div>
      
      <button className="register-button" type="submit" disabled={loading}>
        {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Register'}
      </button>
    </form>
  );
}

export default Register;
