import React from 'react';
import './Footer.css';
import twitter from '../../../images/twitter.png';
import medium from '../../../images/medium.png';
import discord from '../../../images/discord.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <small className="footer-text">
          © {new Date().getFullYear()} - Oregano Finance LTD
          <br/>8 Queen's Gate Terrace, London, England, SW7 5PF
        </small>
        <div className="footer-icons">
          <a
            href="https://medium.com/@indicators_success_rate"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
            <img src={medium} alt="Medium" className="white-image" />
          </a>
          <a
            href="https://twitter.com/ISuccessRate"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
            <img src={twitter} alt="Twitter" className="white-image" />
          </a>
          <a
            href="https://discord.gg/hb52KwMH6x"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
            <img src={discord} alt="Discord" className="white-image" />
          </a>
        </div>
        
      </div>
    </footer>
  );
};

export default Footer;
