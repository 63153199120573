import React, { useState } from 'react';
import SendToken from '../dashboard/dashboardPortfolio/sendToken/SendToken';
import './ModalComponent.css';

const ModalComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button className="add-token" onClick={openModal}>Add Token +</button>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>
              ×
            </button>
            <SendToken/>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalComponent;
