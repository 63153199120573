function calculateSuccessProbability(signals) {
  const numCrossovers = signals.filter((s, i) => i > 0 && signals[i - 1] !== s && s !== 0).length;
  const numSuccessfulCrossovers = signals.filter((s, i) => i > 0 && signals[i - 1] !== s && s !== 0 && s === 1).length;
  const successProbability = numSuccessfulCrossovers / numCrossovers;
  return successProbability;
}



// Function to calculate SMA
function calculateSMA(data, period) {
  const sma = [];
  for (let i = 0; i < data.length; i++) {
    if (i < period - 1) {
      sma.push(null);
    } else {
      let sum = 0;
      for (let j = i - (period - 1); j <= i; j++) {
        sum += data[j].close;
      }
      sma.push(sum / period);
    }
  }
  return sma;
}

// Function to calculate crossover signals
function calculateCrossoverSignals(data, shortSma, longSma) {
  const signals = [];
  for (let i = 0; i < data.length; i++) {
    if (shortSma[i] === null || longSma[i] === null) {
      signals.push(0);
    } else if (shortSma[i] > longSma[i]) {
      signals.push(1);
    } else if (shortSma[i] < longSma[i]) {
      signals.push(-1);
    } else {
      signals.push(0);
    }
  }
  return signals;
}

export {calculateSuccessProbability, calculateCrossoverSignals, calculateSMA }

  