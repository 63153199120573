import React, { useState, useEffect } from 'react';
import { getBinanceData } from '../../../api/binance';
import { calculateEMA, calculateEMASuccessRate } from './emaCalculator';

const BinanceEMA = ({ symbol, interval }) => {
  const [successRate, setSuccessRate] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const data = await getBinanceData(symbol, interval);
    const ema = calculateEMA(data, [5, 8, 13]);
    const emaSuccessRate = calculateEMASuccessRate(ema);
    setSuccessRate(emaSuccessRate);
    setLoading(false);
  };

  useEffect(() => {
    // Removed fetchData call from here
  }, [symbol, interval]);

  const handleButtonClick = () => {
    fetchData();
    setButtonClicked(true);
  };

  return (
    <div className="binance-ema">
      <button onClick={handleButtonClick}>Calculate Success Rate EMA</button>
      {buttonClicked && !loading && (
        <>
          {successRate !== null && symbol !== null && (
            <p><strong>Success Rate: {successRate.toFixed(2)}%</strong></p>
          )}
        </>
      )}
    </div>
  );
};

export default BinanceEMA;
