import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import './BinanceOBV.css';

const DetailsOBV = ({ obvDetails }) => {
  const formatTime = (time) => {
    const date = new Date(time);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const formattedTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedTime;
  };

  const sortedDetails = obvDetails.sort((a, b) => new Date(b.time) - new Date(a.time));

  const exportToCSV = () => {
    const csvContent = [
      'Time,Close,OBV,Signal,Success',
      ...sortedDetails
        .filter((detail) => detail.signal !== null)
        .map((item) =>
          `${formatTime(item.time)},${item.close.toFixed(8)},${item.obv.toFixed(2)},${item.signal},${item.success ? 'Success' : 'Fail'}`
        ),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'obv_details.csv';
    link.click();
  };

  return (
    <div className="details-obv">
      <h4>
        OBV Details{' '}
        {sortedDetails.some((detail) => detail.signal !== null) && (
          <button className="button-csv" onClick={exportToCSV}>
            <FontAwesomeIcon icon={faDownload} className="download-icon" /> Export to CSV
          </button>
        )}
      </h4>
      <table>
        <thead>
          <tr>
            <th>Time</th>
            <th>Close</th>
            <th>OBV</th>
            <th>Signal</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          {sortedDetails
            .filter((detail) => detail.signal !== null)
            .map((item, index) => (
              <tr key={index}>
                <td>{formatTime(item.time)}</td>
                <td>{item.close.toFixed(8)}</td>
                <td>{item.obv.toFixed(2)}</td>
                <td>{item.signal}</td>
                <td>{item.success ? 'Success' : 'Fail'}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default DetailsOBV;
