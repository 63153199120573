import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './BlogPost.css'
import { API_BASE_URL } from '../../config';


function BlogPost() {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchBlog() {
      try {
        const response = await fetch(`${API_BASE_URL}/blog/${slug}`);
        if (!response.ok) throw new Error('Network response was not ok ' + response.statusText);
        const data = await response.json();
        setBlog(data);
      } catch (error) {
        console.error('Error fetching blog:', error);
        setError('Failed to fetch blog');
      }
    }
    fetchBlog();
  }, [slug]);

  if (error) return <p>{error}</p>;
  if (!blog) return <p>Loading blog...</p>;

  return (
    <div>
      <div className='blog-overlay'>
        <h1 className='blog-title'>{blog.title}</h1>
      </div>
      <div className="blog-cover" style={{ backgroundImage: `url("${blog.linkOne}")` }}></div>
      <p className='blog-p'>Krakow, 25 October 2023</p>
      <h3 className='blog-sub'>{blog.subtitle}</h3>
      <p className='blog-p'>{blog.contentOne}</p>
      <p className='blog-p'>{blog.contentTwo}</p>
      <div className="blog-section">
        <div className="blog-column">
          <h3 className='blog-sub-par'>{blog.paragraphTitleOne}</h3>
          <p className='blog-p'>{blog.paragraphOne}</p>
        </div>
        <div className="blog-column">
          <h3 className='blog-sub-par'>{blog.paragraphTitleTwo}</h3> {/* Updated title */}
          <p className='blog-p'>{blog.paragraphTwo}</p>
        </div>
      </div>

      <div className="highlighted-section">
        <h3 className='blog-sub-h'>{blog.contentHighlight}</h3>
      </div>

      <div className="blog-image" style={{ backgroundImage: `url("${blog.linkTwo}")` }}></div>
      <p className='blog-p'>{blog.contentThree}</p>
      <p className='blog-p'>{blog.contentFour}</p>
      <p className='blog-p'>{blog.contentFive}</p>
      <p className='blog-p'>{blog.contentSix}</p>
      <p className='blog-p'>{blog.contentSeven}</p>
      <p className='blog-p'>{blog.contentEight}</p>
      <p className='blog-p'><br></br><br></br>Author: {blog.author}</p>
      <div className="highlighted-section">

      </div>
    </div>
  );
}

export default BlogPost;
