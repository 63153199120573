import './Card.css';
import Form from '../../form/Form';

const Card = (props) => {
  return (
    <div className="background-container">
      <div className="background-overlay"></div>
      <h1 className="mobile-h1">INDICATOR SUCCESS RATE</h1>
      <p className="subtitle">
        Evaluating the performance of technical analysis across different markets and time periods.
        <br></br>
        <br></br>
        {/* <img className='coin' src='https://s2.coinmarketcap.com/static/img/coins/64x64/52.png' alt="XRP" />XRPL Grant Recipient  */}
      </p>
      <div className="card">
        <Form />
        
      </div>
    </div>
  );
}

export default Card;
