// checkYourInbox
import React from 'react';
import './Register.css';
import email from '../../../images/email.png'

function CheckYourInbox() {
    return (
      <div>
        <img className='email-logo' src={email} alt='email logo'/>
        <h2 className='confirm-email-title'>Confirm Your Email</h2>
        <p className='confirm-email-text'>
        You’re almost there!
        <br></br>
        <br></br>
        <b>Check your email and click the link</b> to activate your account.
        <br></br>
        <br></br>
        If you don’t see it, you may need to check your spam folder. 
        <br></br>
        <br></br>
        <br></br>
        Still can’t find the email? Contact us at info@indicatorsuccessrate.com
        </p>
      </div>
    );
}

export default CheckYourInbox;
