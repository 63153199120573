import React, { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import './DashboardApi.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import XummSubscriptionButton from '../../xumm/XummSubscriptionButton';
import { Link } from 'react-router-dom';

function DashboardApi() {
  const { auth } = useContext(AuthContext);

  const handleCopyClick = () => {
    const apiKeyText = document.getElementById('apiKeyText');
    if (apiKeyText) {
      const range = document.createRange();
      range.selectNode(apiKeyText);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
    }
  };

  const handleRedirectClick = () => {
    const url = `https://testnet.xrpl.org/accounts/${auth.xrpAddress}`;
    window.open(url, '_blank');
  };

  const openDhaliPaymentDialog = () => {
    var myWindow = window.open("https://pay.dhali.io?exampleGetUrl=https://run.api.dhali.io/39d1c91c-750b-4294-94fb-69e1b74c1162", "MsgWindow", "width=500,height=500");
    myWindow.focus();
  };

  return (
    <div className="home-container">
      <div className="card-api">
        <Link to="/docs"><h3 className='docs-link'>REST API Documentation</h3></Link>
        <h2 className='dashboard-title'>ACCOUNT</h2>
        <p className="api-key-text">
          {auth.subscriptionData === 'Free' ? (
            <><b>{auth.subscriptionData}:</b> 25 requests per day</>
          ) : auth.subscriptionData === 'Earlybird' ? (
            <><b>{auth.subscriptionData}:</b> 75 requests per minute</>
          ) : null}
        </p>
        <p className="api-key-text">
          <b>API: </b> 
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a id="apiKeyText" className='button-copy' onClick={handleCopyClick}>
            {auth.apiKey}
            <FontAwesomeIcon icon={faCopy} />
          </a>
        </p>
      </div>

      <div className="card-api">
        <h2 className='dashboard-title'>Pay-per-request via Dhali</h2>
        <p className="api-key-text">
          Connect with Dhali to effortessly pay only for the requests you make.<br/><br/>
          {/* <b>Rate Limit:</b> 75 requests per minute<br/><br/> */}
          <b>Cost per request:</b> 0.001 XRP
        </p>
        <button onClick={openDhaliPaymentDialog} className="button-dhali">
          Pay with Dhali
        </button>
      </div>

      {auth.subscriptionData === 'Consumption' && auth.xrpAddress ? (
        <div className="card-api">
          <h2 className='dashboard-title'>XRP (Testnet) Wallet</h2>
          <p className="api-key-text">
            <b>Address: </b>
            {auth.xrpAddress}
            <button className='button-copy' onClick={handleRedirectClick}>
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </button>
            <br></br>
            Deposit XRP (Testnet) to start using the API
            <br></br>
            <b>Fee: </b> 0.0001 XRP / API-call
          </p>
        </div>
      ) : auth.subscriptionData !== 'Consumption' && auth.subscriptionData !== 'Earlybird' ? (
        <>
        
        <div className="card-api">
          <h2 className='dashboard-title'>Upgrade to Early Bird Access</h2>
          <p className="api-key-text">
            Upgrading to the Early Bird Access plan unlocks a higher rate limit, 
            allowing for more frequent data retrieval and a smoother user experience.<br/><br/>
            <b>Rate Limit:</b> 75 requests per minute
          </p>
          <p className="api-key-text">
            $499.99 Lifetime <br></br>
            - Lifetime -
          </p>
          <XummSubscriptionButton/>
        </div>
        </>
      ) : null}
    </div>
  );
}

export default DashboardApi;
