import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PhotoCard from '../landingPage/photoCard/PhotoCard';
import first from '../../images/first.png';
import { API_BASE_URL } from '../../config';

function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchBlogs() {
      try {
        const response = await fetch(`${API_BASE_URL}/blog`);  // Adjust the URL as per your setup
        if (!response.ok) throw new Error('Network response was not ok ' + response.statusText);
        const data = await response.json();
        setBlogs(data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
        setError('Failed to fetch blogs');
      }
    }
    fetchBlogs();
  }, []);

  if (error) return <p>{error}</p>;
  if (!blogs.length) return <p>Loading blogs...</p>;

  return (
    <ul>
      {blogs.map(blog => (
        <ul key={blog._id}>
          <PhotoCard 
            title={blog.title} 
            subtitle="Instantly determine the success rate of your chosen indicators." 
            coverImage={first}
            link='/'
            />
          <Link to={`/blog/${blog.slug}`}>Read more...</Link>
        </ul>
      ))}
    </ul>
  );
}

export default Blog;
