import React from 'react';
import { PieChart, Pie, Tooltip, Cell, Legend } from 'recharts';
import './PieChart.css';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#a4a4a4']; // Added an extra color for "Others"

const CustomPieChart = ({ assetsData }) => {
    // Step 1: Calculate the total balance
    const totalBalance = assetsData.reduce((sum, { balance }) => sum + balance, 0);

    // Step 2: Filter and group data
    const significantAssets = assetsData.filter(asset => (asset.balance / totalBalance) > 0.1);
    const othersTotalBalance = assetsData.reduce((sum, asset) => {
        return (asset.balance / totalBalance) > 0.1 ? sum : sum + asset.balance;
    }, 0);

    // Include "Others" category if there are assets below the threshold
    if (othersTotalBalance > 0) {
        significantAssets.push({ symbol: 'Others', balance: othersTotalBalance });
    }

    // Step 3: Prepare the chart data
    const chartData = significantAssets.map(({ symbol, balance }) => ({
        name: symbol,
        value: balance
    }));

    return (
        <div className="chart-container">
            <h3 className="chart-title">Allocation</h3>
            <PieChart width={400} height={200}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={chartData}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={4}
                    labelLine={false}
                >
                    {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend align="right" layout="vertical" verticalAlign="middle" />
            </PieChart>
        </div>
    );
};

export default CustomPieChart;
