import React, { useState, useCallback } from 'react';
import { getBinanceData } from '../../../api/binance';
import { calculateRSI, calculateRSISuccessRate } from './rsiCalculator';
import DetailsRSI from './DetailsRSI';



const BinanceRSI = ({ symbol, interval, limit }) => {
  const [rsiDetails, setRsiDetails] = useState([]);
  const [successRate, setSuccessRate] = useState(null);

  const handleButtonClick = useCallback(async () => {
    const data = await getBinanceData(symbol, interval, limit);
    const rsi = calculateRSI(data, 14);
    const { rsiDetails, successRate } = calculateRSISuccessRate(rsi, data);
    setRsiDetails(rsiDetails);
    setSuccessRate(successRate);
  }, [symbol, interval, limit]);

  return (
    <div className="binance-rsi">
      <button className='rsi-button' onClick={handleButtonClick}>Calculate Success Rate RSI</button>
      {successRate !== null && (
        <>
          <h3>
            <strong className='binance-rsi'>Success Rate: {successRate.toFixed(2)}%</strong>
          </h3>
          <DetailsRSI rsiDetails={rsiDetails} />
        </>
      )}
    </div>
  );
};

export default BinanceRSI;
