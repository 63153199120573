import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';

function Logout() {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  const logoutUser = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('xrpAddress');
    localStorage.removeItem('xummAddress');
    localStorage.removeItem('xummToken');
    localStorage.removeItem('apiKey');
    localStorage.removeItem('subscriptionData');

    logout();
    navigate('/'); 
  };

  return (
    <Link to="/" onClick={logoutUser} className="logout-link">
      LOGOUT
    </Link>
  );
}

export default Logout;
