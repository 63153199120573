import React, { useState, useContext } from 'react';
import { AuthContext } from '../AuthContext'; // Import the AuthContext
import { useNavigate } from 'react-router-dom';
import './Xumm.css';
import { API_BASE_URL } from '../../config';

function XummButtonComponent() {
  // eslint-disable-next-line no-unused-vars
  const [xummAddress, setXummAddress] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [xummToken, setXummToken] = useState(null);


  let popupWindow = null; // Direct reference instead of state

  const navigate = useNavigate();

  const { setAuthToken, setApiKey, setSubscriptionData  } = useContext(AuthContext); // Destructure the context methods

  const initiateSignIn = () => {
    const eventSource = new EventSource(`${API_BASE_URL}/user/signin`);

    eventSource.onmessage = (event) => {
    
      const data = JSON.parse(event.data);
      if (data.uuid) {

        // Open the signinUrl in a popup window
        popupWindow = window.open(data.signinUrl.always, 'XummSignIn', 'width=600,height=600');
      } else if (data.xummToken && data.message === 'loggedIn') {
        console.log("Received Data:", data);
        setXummToken(data.xummToken);
        setXummAddress(data.xummAddress);

        // Save the token and apiKey to localStorage
        localStorage.setItem('token', data.token);
        localStorage.setItem('apiKey', data.apiKey);
        localStorage.setItem('xummAddress', data.xummAddress);
        localStorage.setItem('xummToken', data.xummToken);
        if (data.subscription) {
          localStorage.setItem('subscriptionData', JSON.stringify(data.subscription));
          setSubscriptionData(data.subscription);
        }
      

        // Update the context values
        setAuthToken(data.token);
        setApiKey(data.apiKey);

        navigate('/');

        if (popupWindow && !popupWindow.closed) {
          popupWindow.close();
        }
      } else if (data.xummToken && data.message === 'Registered') {
        setXummToken(data.xummToken);
        navigate('/confirm-xumm');

        if (popupWindow && !popupWindow.closed) {
          popupWindow.close();
        }
      } else if (data.error && data.error === 'Payload expired') {
        if (popupWindow && !popupWindow.closed) {
          popupWindow.close();
        }
        eventSource.close();
      }
    };

    eventSource.onerror = (error) => {
      if (eventSource.readyState === EventSource.CLOSED) {
        console.log("EventSource connection was closed by the server.");
      } else if (eventSource.readyState === EventSource.CONNECTING) {
        console.log("EventSource is trying to reconnect.");
      } else {
        console.error("EventSource encountered an error:", error);
      }
      eventSource.close();
    };

    return () => {
      eventSource.close();
      if (popupWindow && !popupWindow.closed) {
        popupWindow.close();
      }
    };
  };

  return (
    <div>
      <button className='connect-xumm-button' onClick={initiateSignIn}>Connect Xaman</button>
    </div>
  );
}

export default XummButtonComponent;
