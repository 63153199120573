import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import './Modal.css'
import Register from '../register/Register';
import XummButtonComponent from '../../xumm/XummButtonComponent';
import Login from '../login/Login';
import CheckYourInbox from '../register/checkYourInbox';

const Modal = ({ isOpen, onClose, activeTab }) => {
  const [activeTabState, setActiveTabState] = useState(activeTab);
  const [isRegistered, setIsRegistered] = useState(false);
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    if (isOpen) {
      setActiveTabState(activeTab);
      setIsRegistered(false); // Reset the registration state when the modal opens
    }
  }, [isOpen, activeTab]);

  useEffect(() => {
    if (auth && auth.token) {
      onClose();
    }
  }, [auth, onClose]);

  const handleOverlayClick = (e) => {
    e.stopPropagation();
    onClose();
  };

  const handleModalContentClick = (e) => {
    e.stopPropagation();
  };

  const handleCloseButtonClick = () => {
    onClose();
  };

  const closeModal = () => {
    onClose();
  };

  const handleRegistrationComplete = () => {
    setIsRegistered(true); // Update the state to indicate registration is complete
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
        <div className="modal" onClick={handleModalContentClick}>
            <>
              <button className="modal-close-button" onClick={handleCloseButtonClick}>x</button>
            </>
            <div className="modal-content-login">
                {isRegistered ? (
                  <CheckYourInbox />
                ) : (
                  <>
                    <div className="modal-tabs">
                        <button
                            className={`modal-tab ${activeTabState === 'register' ? 'active' : ''}`}
                            onClick={() => setActiveTabState('register')}
                        >
                            Register
                        </button>
                        <button
                            className={`modal-tab ${activeTabState === 'login' ? 'active' : ''}`}
                            onClick={() => setActiveTabState('login')}
                        >
                            Login
                        </button>
                    </div>
                    
                    {activeTabState === 'register' && <Register onRegistrationComplete={handleRegistrationComplete} />}
                    {activeTabState === 'login' && <Login closeModal={closeModal} />}
                    
                    <br />
                    or 
                    <XummButtonComponent />
                  </>
                )}
            </div>
        </div>
    </div>
  );
};

export default Modal;
