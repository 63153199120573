import React, { useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Login from '../login/Login';
import './confirmEmail.css'
import { API_BASE_URL } from '../../../config';

function ConfirmEmail() {
  const { token } = useParams();
  // const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        await axios.get(`${API_BASE_URL}/user/confirm-email/${token}`);
        alert('Email confirmed successfully!');
        // setIsModalOpen(true); 
      } catch (error) {
        console.log(error);
      }
    };

    confirmEmail();
  }, [token]);

  return (
    <div className='container-confirm-email'>
      <div className='sub-container-confirm-email'>
        <h3 className='confirm-email-h3'>Your email has been confirmed successfully.<br></br></h3>
        <h2 className='confirm-email-title'>
            Log in to access your account!
        </h2>
        <Login/>

      </div>
    </div>
  );
}

export default ConfirmEmail;
