import { useRef, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import "./App.css";
import { AuthContext, AuthProvider } from './components/AuthContext';
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/landingPage/footer/Footer";
import Card from "./components/landingPage/card/Card";
import Faq from "./components/landingPage/faq/Faq";
// eslint-disable-next-line
import SectionOne from "./components/landingPage/sectionOne/SectionOne";
// import SectionTwo from "./components/sectionTwo/SectionTwo";
import SectionThree from "./components/landingPage/sectionThree/SectionThree";
import SectionColums from './components/landingPage/sectionColums/sectionColums';
// eslint-disable-next-line
import Pricing from './components/pricing/Pricing';
// import EBook from './components/eBook/EBook'; 
import DashboardApi from './components/dashboard/dashboardApi/DashboardApi';
import DashboardCalculator from './components/dashboard/dashboardCalculator/DashboardCalculator';
import Blog from './components/blog/Blog';
import BlogPost from './components/blog/BlogPost';
import FirstChoice from './components/pricing/firstChoice/FirstChoice';
import ConfirmEmail from './components/authentication/confirmEmail/confirmEmail';
import ForgotPassword from './components/authentication/forgotPassword/ForgotPassword';
import ResetPassword from './components/authentication/resetPassword/resetPassword';
import Dashboard from './components/dashboard/Dashboard';
import ApiDocumentation from './components/documentation/Documentation';
import CoinTable from './components/coinTable/CoinTable';
import PortfolioInitialization from './components/dashboard/dashboardPortfolio/portfolioInitialization/portfolioInitialization';

const AppContent = () => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  const elementRef = useRef(null); // Create a ref

  useEffect(() => {
    if (location.hash) {
      // const id = location.hash.replace("#", "");
      const element = elementRef.current; // Use ref instead of direct DOM manipulation
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  

  return (
    <>
      <Navbar/>
      <Routes>
      <Route path="/test" element={!auth.token ? <Card /> : <Dashboard />} />
        <Route path="/" element={!auth.token ? <Card /> : <DashboardCalculator />} />
        <Route path="/market-analysis" element={<CoinTable />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/portfolio" element={<PortfolioInitialization />}/>
        <Route path="/docs" element={<ApiDocumentation />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
        {auth.token && <Route path="/api" element={<DashboardApi />} />}
        {auth.token && <Route path="/upgrade" element={<FirstChoice />} />}
      </Routes>
      
      {(!auth.token) && (
        <>
        {(!location.pathname.includes('/confirm-email')) && (!location.pathname.includes('/docs')) && (
          <>
            <SectionThree />
            {/* <SectionOne /> */}
            {/* <ApiDocumentation /> */}
            <SectionColums />
            {/* <Pricing id="pricing" /> */}
            {/* <SectionTwo /> */}
            {/* <EBook /> */}
            <Faq/>
            <Footer />
          </>

          )
        }
        </>
      )}
    </>
  );
}

const App = () => {
  return (
    <Router basename='/' >
      <div className="App">
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </div>
    </Router>
  );
}

export default App;