import './FirstChoice.css';
import PricingCardFree from '../pricingCards/PricingCardFree';
import PricingCardEarlyBird from '../pricingCards/PricingCardEarlyBird';

const FirstChoice = (props) => {
  return (
    <div id={props.id} className="first-background-container-pr">
        <div className="first-background-overlay-pr"></div>
        {/* <h1 className='plans-title'>ONLY FOR YOU, TODAY</h1>
        <p>Cheers!</p> */}
        <div className='pricing-cards'>
          <PricingCardEarlyBird 
          subtitle="EARLY BIRD - LIFETIME"
          priceClass="pro"
          title="$499.99" 
          one=" 75 API requests per minute"
          two=" No daily limits"
          three=" All premium features unlocked"
          four=" No hidden costs"
          five=" No surprise overage charges"
          />

          <PricingCardFree 
          subtitle="FREE"
          priceClass="freemium"
          title="$0"
          one=" 25 API requests per day"
          two=" -"
          three=" -"
          four=" -"
          five="-"
          />
          
        </div>
    </div>
  );
}

export default FirstChoice;
