import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './resetPassword.css'
import { API_BASE_URL } from '../../../config';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();

  const resetPassword = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_BASE_URL}/user/reset-password/${token}`, { password });
      alert('Password reset successfully!');
      navigate('/');
    } catch (error) {
      console.error('Error during password reset:', error);
      alert('Error during password reset. Please try again.');
    }
  };

  return (
    <form className="reset-password-form" onSubmit={resetPassword}>
      <h2>Reset Password</h2>
      <input type="password" placeholder="New password" value={password} onChange={e => setPassword(e.target.value)} required />
      <button type="submit">Reset</button>
    </form>
  );
}

export default ResetPassword;