import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
      token: localStorage.getItem('token') || null,
      email: localStorage.getItem('email') || null,
      xrpAddress: localStorage.getItem('xrpAddress') || null,
      xummAddress: localStorage.getItem('xummAddress') || null,
      apiKey: localStorage.getItem('apiKey') || null,
      subscriptionData: JSON.parse(localStorage.getItem('subscriptionData')) || null,
  });

  const [authChangeTrigger, setAuthChangeTrigger] = useState(false);

  const [currencySymbol, setCurrencySymbol] = useState(localStorage.getItem('currencySymbol') || 'USD');

  const navigate = useNavigate();

  const setCurrencySymbolFunc = (symbol) => {
    setCurrencySymbol(symbol);
    localStorage.setItem('currencySymbol', symbol);
  };

  const setAuthToken = (token) => {
    setAuth((prevState) => ({ ...prevState, token }));
    setAuthChangeTrigger((prev) => !prev);
  };

  const setEmail = (email) => {
    setAuth((prevState) => ({ ...prevState, email }));
    setAuthChangeTrigger((prev) => !prev);
  };

  const setXrpAddress = (xrpAddress) => {
    setAuth((prevState) => ({ ...prevState, xrpAddress }));
    localStorage.setItem('xrpAddress', xrpAddress);
  };

  const setXummAddress = (xummAddress) => {
    setAuth((prevState) => ({ ...prevState, xummAddress }));
    localStorage.setItem('xummAddress', xummAddress);
  };

  const setXummToken = (xummToken) => {
    setAuth((prevState) => ({ ...prevState, xummToken }));
    localStorage.setItem('xummToken', xummToken);
  };

  const setApiKey = (apiKey) => {
    setAuth((prevState) => ({ ...prevState, apiKey }));
    localStorage.setItem('apiKey', apiKey);
  };  

  const logout = () => {
    setAuth({
      token: null,
      email: null,
      xrpAddress: null,
      xummAddress: null,
      xummToken: null,
      apiKey: null,
      subscriptionData: JSON.parse(localStorage.getItem('subscriptionData')) || null,
    });
    setAuthChangeTrigger((prev) => !prev);
  };

  const setSubscriptionDataFunc = (data) => {
    setAuth((prevState) => ({ ...prevState, subscriptionData: data }));
    localStorage.setItem('subscriptionData', JSON.stringify(data));
  };

  useEffect(() => {
      const storedXrpAddress = localStorage.getItem('xrpAddress');
      const storedXummAddress = localStorage.getItem('xummAddress');
      const storedXummToken = localStorage.getItem('xummToken');
      const storedApiKey = localStorage.getItem('apiKey');
      const storedSubscriptionData = JSON.parse(localStorage.getItem('subscriptionData'));
      const storedCurrencySymbol = localStorage.getItem('currencySymbol');

      if (storedCurrencySymbol) {
          setCurrencySymbolFunc(storedCurrencySymbol);
      }

      if (storedXrpAddress) {
          setXrpAddress(storedXrpAddress);
      }

      if (storedXummAddress) {
          setXummAddress(storedXummAddress);
      }

      if (storedXummToken) {
          setXummToken(storedXummToken);
      }

      if (storedApiKey) {
          setApiKey(storedApiKey);
      }

      if (storedSubscriptionData) {
          setSubscriptionDataFunc(storedSubscriptionData);
      }
  }, []);

  useEffect(() => {
    if (!auth.token) {
      navigate('/');
    }
  }, [auth.token, navigate]);

  return (
    <AuthContext.Provider
        value={{ 
            auth, 
            setAuthToken, 
            setEmail, 
            logout, 
            setXrpAddress, 
            setXummAddress,
            setXummToken,
            setApiKey, 
            authChangeTrigger,
            setSubscriptionData: setSubscriptionDataFunc,
            currencySymbol,
            setCurrencySymbol: setCurrencySymbolFunc
        }}
    >
        {children}
    </AuthContext.Provider>
);
}
