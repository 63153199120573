import React, { useContext, useEffect, useState, useCallback } from 'react';
import { AuthContext } from '../../../AuthContext';
import { decompressFromUTF16 } from 'lz-string';
import './SendToken.css';
import { API_BASE_URL } from '../../../../config';

const SendToken = () => {
  const { auth } = useContext(AuthContext);
  const [tokensData, setTokensData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTokenIndex, setSelectedTokenIndex] = useState(0);
  // Additional states
  const [transactionResult, setTransactionResult] = useState(null);
  const [transactionError, setTransactionError] = useState(null);
  const [value, setValue] = useState('');

  // Function to load tokens
  const loadTokens = useCallback(() => {
    const compressedTokens = localStorage.getItem('xrplTokens');
    if (compressedTokens) {
      const decompressedTokens = decompressFromUTF16(compressedTokens);
      const parsedTokens = JSON.parse(decompressedTokens);
      setTokensData(parsedTokens);
      if (parsedTokens.length > 0) {
        setSelectedTokenIndex(0);
      }
    }
    setLoading(false);
  }, []);

  // Initial load of tokens
  useEffect(() => {
    loadTokens();
  }, [loadTokens]);

  // Polling mechanism to check for tokens periodically
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (tokensData.length === 0) {
        loadTokens();
      }
    }, 1000); // Poll every second. You can adjust the timing as needed.

    // Clear interval on cleanup
    return () => clearInterval(intervalId);
  }, [tokensData.length, loadTokens]);

  const handleTokenChange = (e) => {
    setSelectedTokenIndex(parseInt(e.target.value, 10));
  };

  const selectedToken = selectedTokenIndex !== null ? tokensData[selectedTokenIndex] : null;

  const sendTokenToAny = () => {
    setLoading(true); // Start loading
    setTransactionResult(null); // Reset previous results
    setTransactionError(null); // Reset previous errors

    const sendUrl = `${API_BASE_URL}/xrpl/send-token-to-any`;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth.token}`
    };

    const body = JSON.stringify({
      email: auth.email || '',
      symbol: selectedToken?.symbol || '',
      currencyHex: selectedToken?.response?.currency || '',
      value: value.toString()
    });

    fetch(sendUrl, {
      method: 'POST',
      headers: headers,
      body: body
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
        if (data) {
          setTransactionResult('Transaction completed successfully.');
        } else {
          setTransactionError('Transaction failed: Unknown error occurred.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setTransactionError(error.toString());
      })
      .finally(() => {
        setLoading(false); // Stop loading regardless of result
      });
  };

  return (
    <div className="send-token-form">
      <h2>Add Token</h2>
      {loading && <div className="loader"></div>}
      <div>
        <label htmlFor="amount">
          Amount:
          <input
            type="number"
            id="amount"
            min="100"
            max="100000"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </label>
      </div>
      <div>
        <label htmlFor="token">Token:</label>

          <select
            id="token"
            value={selectedTokenIndex}
            onChange={handleTokenChange}
          >
            {tokensData.map((token, index) => (
              <option key={index} value={index}>
                {`${token.symbol} (${token.response.currency})`}
              </option>
            ))}
          </select>

      </div>
      <div>
        <button className='add-token-button' onClick={sendTokenToAny} disabled={loading}>ADD</button>
      </div>
      {transactionResult && <div className="transaction-result">{transactionResult}</div>}
      {transactionError && <div className="transaction-error">{transactionError}</div>}
    </div>
  );
};

export default SendToken;
