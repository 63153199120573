import React, { useState, useContext } from 'react';
import './Login.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { API_BASE_URL } from '../../../config';

function Login({ closeModal }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const { setAuthToken, setXrpAddress, setApiKey, setSubscriptionData } = useContext(AuthContext);
  const location = useLocation(); // Get the current location
  const isConfirmEmailRoute = location.pathname.includes('/confirm-email');

  // Define the style object conditionally based on the route
  const inputStyle = {
    borderColor: isConfirmEmailRoute ? '#000' : '#9b9b9b',
    color: isConfirmEmailRoute ? '#000' : '#444',
  };

  

  const loginUser = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${API_BASE_URL}/user/login`, { email, password });
  
      if (res.data.error) {
        setErrorMessage(res.data.error);
      } else {
        const { token, email, xrpAddress, apiKey, subscription } = res.data;  // <-- added subscription here
  
        localStorage.setItem('token', token);
        localStorage.setItem('email', email);
        localStorage.setItem('xrpAddress', xrpAddress);
        localStorage.setItem('apiKey', apiKey);
        localStorage.setItem('subscriptionData', JSON.stringify(subscription));

  
        setAuthToken(token);
        setEmail(email);
        setXrpAddress(xrpAddress);
        setApiKey(apiKey);
        setSubscriptionData(subscription);  // <-- save subscription to context
  
        setEmail('');
        setPassword('');
        setErrorMessage(null);

        if (subscription==='Free') {
          navigate('/market-analysis');
        } else {
          navigate('/');
        }
  
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
        setErrorMessage(err.response.data.error);
      } else {
        setErrorMessage('Invalid Credentials');
      }
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleForgotPasswordClick = () => {
    // Close the modal when the link is clicked
    closeModal();
    
  };
  

  return (
    <form className="login-form" onSubmit={loginUser}>
      {errorMessage && <p className="error">{errorMessage}</p>}
      <input
        style={inputStyle}
        className="login-input"
        type="text"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <div className="password-container">
        <input
          style={inputStyle}
          className="login-input"
          type={passwordVisible ? "text" : "password"}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <FontAwesomeIcon
          icon={passwordVisible ? faEyeSlash : faEye}
          onClick={togglePasswordVisibility}
          className="password-toggle-icon"
        />
      </div>
      <button className="login-button" type="submit">
        Login
      </button>
      <br />
      <div className="forgot-password-link">
        <a href='/forgot-password' onClick={handleForgotPasswordClick}>Forgot Password?</a>
      </div>
    </form>
  );
}

export default Login;
