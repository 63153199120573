import React from 'react';
// import { useNavigate } from 'react-router-dom';
import './PricingCardEarlyBird.css';
import checkBlack from '../../../images/check-white.png'
import XummSubscriptionButton from '../../xumm/XummSubscriptionButton';


function PricingCardEarlyBird(props) {
  // const navigate = useNavigate();

  return (
    <>
    <div className="early-price-card">
      {/* <div className={`early-price-card-subtitle ${props.priceClass}`}>{props.subtitle}</div> */}
      <div className="early-price-card-title">{props.title}</div>
      <h2 className='early-title'>Early Bird - LIFETIME
        <p className='early-paragraph'>Unlock premium benefits with our upgraded plan.</p>
      </h2>
      <div className='early-price-list'>
        <div className='early-price-item'><img src={checkBlack} alt="Check" className='check-black' />&#160;&#160;{props.one}</div>
        <div className='early-price-item'><img src={checkBlack} alt="Check" className='check-black' />&#160;&#160;{props.two}</div>
        <div className='early-price-item'><img src={checkBlack} alt="Check" className='check-black' />&#160;&#160;{props.three}</div>
        <div className='early-price-item'><img src={checkBlack} alt="Check" className='check-black' />&#160;&#160;{props.four}</div>
        <div className='early-price-item'><img src={checkBlack} alt="Check" className='check-black' />&#160;&#160;{props.five}</div>
      </div>
      <XummSubscriptionButton/>
      {/* <a onClick={() => navigate('/')}>Skip</a> */}
    </div>
    </>
  );
}

export default PricingCardEarlyBird;
