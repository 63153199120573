import React from 'react';
import 'font-awesome/css/font-awesome.min.css'; // Import Font Awesome CSS
import './FiatSwitcher.css';

const FiatSwitcher = ({ currencySymbol, onCurrencyChange, availableCurrencies }) => {
    return (
        <div className="select-container"> {/* Wrap the select in a div with select-container class */}
            <select value={currencySymbol} onChange={e => onCurrencyChange(e.target.value)}>
                {availableCurrencies.map(currency => (
                    <option key={currency} value={currency}>
                        {currency}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default FiatSwitcher;
