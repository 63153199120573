import React, { useState } from 'react';
import Modal from '../../authentication/modal/Modal';
import './SectionThree.css';

const SectionThree = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('register');

  const openModal = (tab) => {
    setActiveTab(tab);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  const handleKeyDown = (event, tab) => {
    if (event.key === 'Enter') {
      openModal(tab);
    }
  };

  return (
    <>
    <div className="third-section-container">
      <div className="third-content-container">
        <div className="third-title-left">
          <h1 className="third-title">REST API</h1>
          <p className='mobile-p'>
           TA indicators and candles pattern data for seamless integration into your projects.
          </p> 
          <button className="third-button" onClick={() => openModal('register')} onKeyDown={(e) => handleKeyDown(e, 'register')}>Free API Key</button>
        </div>

      </div>
    </div>
    <Modal isOpen={isModalOpen} onClose={closeModal} activeTab={activeTab} />
    </>
  );
};

export default SectionThree;
