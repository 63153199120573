import React from 'react';
import './PhotoCard.css';

function PhotoCard(props) {
  return (
    <div className="photo-card">
      <div className="photo-card-cover" style={{ backgroundImage: `url(${props.coverImage})` }}></div>
      <div className="photo-card-title">{props.title}</div>
      <div className="photo-card-subtitle">{props.subtitle}</div>
      <div className="photo-card-subtitle"><a href={props.link}>Read Article...</a></div>
    </div>
  );
}

export default PhotoCard;
