import React from 'react';
import './FullScreenModal.css'; // Import the CSS for styling

const FullScreenModal = ({ isOpen, logo }) => {
    if (!isOpen) return null; // Don't render the modal unless it's open

    return (
        <div className="fullscreen-modal">
            <div className="modal-content">
                <h1>Loading ... </h1>
            </div>
        </div>
    );
};

export default FullScreenModal;
