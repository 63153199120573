const calculateEMA = (data, periods) => {
    if (!data.length || !periods.length) {
      return [];
    }
  
    const ema = periods.map((period) => {
      const multiplier = 2 / (period + 1);
      const emaArray = [data[0].close];
  
      for (let i = 1; i < data.length; i++) {
        const emaValue = (data[i].close - emaArray[i - 1]) * multiplier + emaArray[i - 1];
        emaArray.push(emaValue);
      }
  
      return emaArray;
    });
  
    return ema;
  };
  
  const calculateEMASuccessRate = (emaValues) => {
    if (!emaValues.length || !emaValues.every((v) => v.length)) {
      return 0;
    }
  
    let successfulSignals = 0;
    let totalSignals = 0;
  
    for (let i = 1; i < emaValues[0].length; i++) {
      const crossAbove = emaValues.every((values, j, arr) => {
        return (
          values[i] > values[i - 1] &&
          (j === 0 || values[i] > arr[j - 1][i])
        );
      });
  
      const crossBelow = emaValues.every((values, j, arr) => {
        return (
          values[i] < values[i - 1] &&
          (j === 0 || values[i] < arr[j - 1][i])
        );
      });
  
      if (crossAbove || crossBelow) {
        totalSignals++;
        if (crossAbove && emaValues[0][i + 1] > emaValues[0][i]) {
          successfulSignals++;
        } else if (crossBelow && emaValues[0][i + 1] < emaValues[0][i]) {
          successfulSignals++;
        }
      }
   
    }

    return totalSignals ? (successfulSignals / totalSignals) * 100 : 0;
  };
  
  export { calculateEMA, calculateEMASuccessRate };
    