import React, { useState, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import logo from '../../images/logo.png';
import Logout from '../authentication/logout/Logout';
import Modal from '../authentication/modal/Modal';

const Navbar = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('register');
  const { auth } = useContext(AuthContext);

  const location = useLocation();
  const isDarkNavbar = auth.token || location.pathname.includes('/blog') || location.pathname.endsWith('/docs');
  const isConfirmEmailPage = location.pathname.startsWith('/confirm-email');


  const openModal = (tab) => {
    setActiveTab(tab);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  const handleKeyDown = (event, tab) => {
    if (event.key === 'Enter') {
      openModal(tab);
    }
  };

  let navbarClass = 'navbar';
  if (isDarkNavbar) {
    navbarClass = 'navbar-dark';
  } 
  if (isConfirmEmailPage) {
    navbarClass = 'navbar-confirm-email';
  }


  return (
    <>
      <header className={navbarClass}>
        <div className='navbar__title navbar__item'>
          <Link to="/">
            <img src={logo} alt="Logo" className="navbar__logo" />
          </Link>
        </div>


          <div className='navbar__item'>
            
            {/* <Link to="/docs">DOCS</Link> */}
            {auth.token ? (
              <>
              {/* <Link to="/upgrade">UPGRADE</Link> */}
              <Link to="/">ISR-Calculator</Link>
              <Link to="/market-analysis">Market-Analysis-(Beta)</Link>
              <Link to="/api">API</Link>
              <Logout />
              </>

            ) : (
              <>
              {/* <Link className='item-mobile' to="https://docs.indicatorsuccessrate.com/" target='_blank'>DEVELOPERS</Link> */}
              {/* <Link to="/#pricing">PRICING</Link> */}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a role="button" tabIndex="0" onClick={() => openModal('login')} onKeyDown={(e) => handleKeyDown(e, 'login')}>
                LOGIN
              </a>

              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a role="button" tabIndex="0" onClick={() => openModal('register')} onKeyDown={(e) => handleKeyDown(e, 'register')}>
                REGISTER
              </a>
              </>
        
            )}
          </div>
   
      </header>
      <Modal isOpen={isModalOpen} onClose={closeModal} activeTab={activeTab} />
    </>
  );
};

export default Navbar;
