import axios from 'axios';

let API_BASE_URL = 'https://api.binance.com';

const determineAPIBaseUrl = async () => {
  try {
    const response = await axios.get('https://geolocation-db.com/json/');
    const { data: { country_code } } = response;
    if (country_code === 'US') {
      API_BASE_URL = 'https://api.binance.us';
    }
  } catch (error) {
    console.error('Error determining API base URL:', error);
  }
};

const getBinanceData = async (symbol, interval, limit) => {
  try {
    let API_BASE_URL = 'https://api.binance.com';

    const determineAPIBaseUrl = async () => {
      try {
        const response = await axios.get('https://geolocation-db.com/json/');
        const { country_code } = response.data;
        if (country_code === 'US') {
          API_BASE_URL = 'https://api.binance.us';
        }
      } catch (error) {
        console.error('Error determining API base URL:', error);
      }
    };

    await determineAPIBaseUrl();

    const response = await axios.get(`${API_BASE_URL}/api/v3/klines`, {
      params: {
        symbol: symbol,
        interval: interval,
        limit: limit,
      },
    });

    const data = response.data.map((entry) => ({
      time: entry[0],
      open: parseFloat(entry[1]),
      high: parseFloat(entry[2]),
      low: parseFloat(entry[3]),
      close: parseFloat(entry[4]),
      volume: parseFloat(entry[5]),
    }));

    // Reverse the order of the data array
    const reversedData = data.reverse();

    return reversedData;
  } catch (error) {
    console.error('Error fetching Binance data:', error);
    return [];
  }
};

const getTradingPairs = async () => {
  try {
    await determineAPIBaseUrl();

    const response = await axios.get(`${API_BASE_URL}/api/v3/exchangeInfo`);
    const symbols = response.data.symbols.map(({ symbol }) => symbol);
    return symbols;
  } catch (error) {
    console.error('Error fetching trading pairs:', error);
    return [];
  }
};

export { getBinanceData, getTradingPairs };
